import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceUnit'
})
export class PriceUnitPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return value == 'm' ? '억' : '만원';
  }

}
