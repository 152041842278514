import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-range-dual',
  templateUrl: './range-dual.component.html',
  styleUrls: ['./range-dual.component.scss'],
})
export class RangeDualComponent implements OnInit, OnChanges {

  public range : any = {
    lower : 0
  };

  @Input('type') type : string;
  @Input('div1') div1 : string;
  @Input('div2') div2 : string;

  @Output() result = new EventEmitter();

  constructor() { 
  }

  ngOnInit() {
    this.range.upper = this.type == 'price' ? 30 : 7;
  }

  ngOnChanges(){
    console.log(this.range);
    this.result.emit(this.range);
  }
  
  test(){
    console.log(this.range);

  }

}
