import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { rootScope } from '../global/global';
import { SessionService } from './session.service';
import { StaticVariableService } from './static-variable.service';
@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  type: { [key: string]: any };

  constructor(public http: HttpClient, public StaticVariable: StaticVariableService, private session: SessionService) {
    this.type = {
      select: "select", insert: "insert", update: "update", delete: "delete",
      action: "action", attach: "attach", excel: "excel", api: "api",
      openApi: "openapi", login: "login",
    };
  }

  makeData(D: any) {
    D.userkey = this.session.user.value.userkey + "-" + this.session.user.value.TenantId;
    return D;
  }

  makeResultData(D) {
    let result: any = {};
    if (D == null || D.responseVO == null) return result;
    result.header = D.responseVO.header;
    result.body = D.responseVO.body;
    return result;
  }

  makeErrorMsg(errorType) {
    let result: any = {};
    if (errorType == "undefinedSqlMap") result.header = { status: 405, msg: "This service requires a 'mapCode' key for data." };
    result.body = {};
    console.error(result.header);
    return result;
  }

  sendPost(url: string, reqData: any, callback, callback2?) {
    this.http.post(url, reqData, this.StaticVariable.getRequestActions.defaultAction()).subscribe(data => {  
      callback(this.makeResultData(data), reqData.mapcode);
    }, function (error) {
      callback2(error, reqData.mapcode);
    });
  }

  getMetaData(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/' + data.mapCode + '/action.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  checkSession(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/login/checkSession.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  signUp(data: any, callback1: Function, callback2?: Function) {
    if (data.mapCode) {
      const url = this.StaticVariable.getUrl('/login/signUp.json');
      let reqData = this.makeData(data);
      reqData.userkey = undefined;
      this.sendPost(url, reqData, function (result) { callback1(result); }, function (result) { callback2(result); });
    } else {
      callback2(this.makeErrorMsg("undefinedSqlMap"));
    }
  }

  dropOut(data : any, callback1 : Function, callback2? : Function){
    const url = this.StaticVariable.getUrl('/logout/dropOut.json');
    const reqData = this.makeData(data);
    this.sendPost(url, reqData, function(result) {callback1(result);}, function(result){callback2(result);});
  }

  duplicateCheck(data: any, callback1: Function, callback2?: Function) {
    if (data.LoginId) {
      const url = this.StaticVariable.getUrl('/login/duplicateCheck.json');
      this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
    } else {
      callback2(this.makeErrorMsg("duplicationCheck Error"));
    }
  }

  duplicateMobileCheck(data: any, callback1: Function, callback2?: Function) {
    if (data.CustNm && data.Mobile) {
      const url = this.StaticVariable.getUrl('/login/duplicateMobileCheck.json');
      this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
    } else {
      callback2(this.makeErrorMsg("duplicationMobileCheck Error"));
    }
  }

  signIn(data: any, callback1: Function, callback2?: Function) {
    if ((data.id && data.pw) || data.TokenId) {
      const url = this.StaticVariable.getUrl('/login/signIn.json');
      console.log("url=", url);
      this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
    } else {
      callback2(this.makeErrorMsg("SignIn Error"));
    }
  }

  signOut(callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/logout/signOut.json');
    this.sendPost(url, {}, function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  insertQuestion(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/insert/insertQuestion.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  insertApp(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/insert/insertApp.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  insertProperty(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/insert/insertProperty.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  updateProperty(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/update/updateProperty.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  deleteProperty(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/delete/deleteProperty.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectRevAuction(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/selectRevAuction.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectPropList(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/selectPropList.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectPropListMin(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/openapi/selectPropListMin.json');
    this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectEnterPriseData(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/openapi/selectEnterPriseData.json');
    this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectRequestList(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/selectRequestList.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  insertProposal(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/insert/insertProposal.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  dropProposal(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/update/dropProposal.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectRevProp(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/selectRevProp.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectAppList(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/selectAppList.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  updatePropose(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/update/updatePropose.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  updateApp(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/update/updateApp.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  deleteApp(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/delete/deleteApp.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  getContact(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/getContact.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  getReviews(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/getReviews.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  reviewsForEnter(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/reviewsForEnter.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  reviewsForMin(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/openapi/reviewsForMin.json');
    this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  insertReview(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/insert/insertReview.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  updateReview(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/update/updateReview.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectCalculate(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/selectCalculate.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  insertCalculate(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/insert/insertCalculate.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  updateCalculate(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/update/updateCalculate.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }
  snsProfile(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/login/snsProfile.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  kakao(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/login/kakao.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }
  selectProfile(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/select/selectProfile.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  updateProfile(data: any, callback1: Function, callback2?: Function) {
    data.CustId = this.session.user.value.CustId;
    const url = this.StaticVariable.getUrl('/update/updateProfile.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  updatePassword(data: any, callback1: Function, callback2?: Function) {
    if (data.key) {
      const url = this.StaticVariable.getUrl('/openapi/updatePassword.json');
      this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
    } else {
      const url = this.StaticVariable.getUrl('/update/updatePassword.json');
      this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
    }
  }



  findId(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/login/findIdPwd.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  insertCampaignImplCustomer2(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/openapi/insertCampaignImplCustomer2.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectIdPassFindCetification(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/openapi/selectIdPassFindCetification.json');
    this.sendPost(url, this.makeData(data), function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectAbroadCustList(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/openapi/selectAbroadCustList.json');
    this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  getStatistics(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/openapi/getStatistics.json');
    this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
  }

  selectDomesticList(data: any, callback1: Function, callback2?: Function) {
    const url = this.StaticVariable.getUrl('/openapi/selectDomesticList.json');
    this.sendPost(url, data, function (result) { callback1(result); }, function (result) { callback2(result); });
  }
}
