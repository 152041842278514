import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavigationService } from 'src/app/service/navigation.service';
import { SessionService } from 'src/app/service/session.service';
import { ToastService } from 'src/app/service/toast-service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {

  public terms : any = {
    service : false,
    personalInfo : false,
  };

  public serviceType;
  @Input() joinWith : string;
  public agreed : boolean = false;

  constructor(public modalCtrl : ModalController, public toastCtrl : ToastService, private session : SessionService, public actRoute: ActivatedRoute) { 
    this.serviceType = actRoute.snapshot.params.serviceType;
  }
  
  ngOnInit() {}

  submit(who : string){
    if(!this.terms.service || !this.terms.personalInfo){
      this.toastCtrl.presentToast('필수 항목에 동의가 필요합니다.');
    }else{
      let userInfo : any = this.session.action.value;
      if(this.joinWith == 'sns'){
        userInfo.marketing = this.terms.marketing;
      }else{
        userInfo = {
          marketing : this.terms.marketing
        }
      }
      userInfo.joinType = who;
      this.session.setAction(userInfo);
      this.modalCtrl.dismiss('join');
    }
  }

  allagree(){
    for(const key in this.terms){
      this.terms[key] = !this.agreed;
    }
  }

  agree(){
    if (this.agreed){
      this.agreed = !this.agreed;
    }    
  }
}
