import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appKtom]'
})
export class KtomDirective {

  constructor(private el : ElementRef) { }

  @HostListener('focusout')
  translate(){
    let value : string = this.el.nativeElement.value;
    if(value.length > 4 && !/[^0-9]/.test(value)){
      const m = value.substring(0, value.length - 4);
      let k : number = parseInt(value.substring(value.length - 4));
      this.el.nativeElement.value = m + '억' + (k > 0 ? k : '');
    }
  }
}
