import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { SrchAddrComponent } from 'src/app/component/srch-addr/srch-addr.component';
import { ToastService } from 'src/app/service/toast-service';
import { booleanToYN, formCheck, rootScope, ValidatorsExt } from 'src/app/global/global';
import { CONSTANT } from 'src/assets/global-constant';
import { RestApiService } from 'src/app/service/rest-api.service';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { IamportCordova } from '@ionic-native/iamport-cordova';
import { Location } from '@angular/common';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
})
export class JoinComponent implements OnInit {

  @Input('keyId') keyId : string;
  @Input('from') from : string;
  
  public data : any = {};
  public joinForm : FormGroup;
  public entForm : FormGroup;
  public submitForm : FormGroup;
  public valid : any;
  public submitted : boolean = false;
  public duplicate : boolean;
  public duplicateMobile: boolean;
  public checkedId : string;
  public custType : string = 'P';
  public pickSpec : boolean = false;
  public verifiedPersonalInfo : boolean = false;
  private userInfo : any;
  public uploadFileList = [];
  public photo;
  public license;
  public biz;
  public snsJoin = false;
  public agreed : boolean = false;
  public tenantTy : boolean = true;
  public joinTy : boolean = true;

  constructor(private modalCtrl : ModalController, public fb : FormBuilder, private toastCtrl: ToastService, public restApi : RestApiService, public router : Router, private session : SessionService, private elRef : ElementRef, private location : Location) { 

    // sns 로그인로 회원가입시 accessToken, id, sns 종류 파악
    this.session.action.subscribe(res => {
      this.userInfo = res;

      // sns-id 가 존재시에 비밀번호 미입력 받기위해(네이버 검수시 필수)
      this.snsJoin = this.userInfo.id ? true : false;
      this.custType = res.joinType == 'p' ? 'P' : 'E';
    });
    
  }
  
  ngOnInit() {
    this.formInit();
    // sns Login & 개인회원 가입시에 자동가입
    if(this.userInfo.id && this.custType == 'P'){
      this.elRef.nativeElement.style.display = 'none'
      this.submit();
    }
  }

  formInit(){
    // 일반 개인정보 입력폼
    this.joinForm = this.fb.group({
      LoginId: ['', [
        Validators.required,
        Validators.minLength(5),
        Validators.pattern("^[a-z0-9_\-]{5,}$"),
      ]],
      Password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern("^(?=.*[a-zA-Z0-9])(?=.*[a-zA-Z!`~@#$%^&*()_=+{}|?<>,./\-])(?=.*[a-z0-9!`~@#$%^&*()_=+{}|?<>,./\-])(?=.*[A-Z0-9!`~@#$%^&*()_=+{}|?<>,./\-]).{8,}$") // 대문자, 소문자, 숫자, 특수문자 중 2개조합 8자리이상
      ]],
      rePassword: ['', [
        Validators.required,
        Validators.minLength(8),
        ValidatorsExt.match
      ]],
      Email: ['', [
        Validators.required,
        Validators.pattern('^[_a-zA-Z0-9-\\.]+@[\\.a-zA-Z0-9-]+\\.[a-zA-Z]+$')
      ]],
      RecomCustId: [this.keyId, []],
      JoinPath: ['DOMESTIC', [
        Validators.required
      ]],
    });
    
    // 기업회원 사업자 등록정보 폼
    this.entForm = this.fb.group({
      CustNm: ['', [
        Validators.required
      ]],
      ZipCode: ['', []],
      Addr1: ['', []],
      Addr2: ['', []],
      Sigungucode: ['', []],
      Mobile: ['', [
        Validators.required,
        Validators.pattern('^[0-9-]{10,11}$')
      ]],
      TenantNm: ['', [Validators.required]],
      TenantType : ['tenant', []],
      Ceo: ['', [Validators.required]],
      TelNo: ['', [
        Validators.required,
        Validators.pattern('^[0-9-]+$')
      ]],
      TradeNo: ['', [
        Validators.required,
        Validators.pattern('^[0-9-]+$')
      ]],
      BizRegNo: ['', [
        Validators.required,
        Validators.pattern('^[0-9-]+$')
      ]],
      TenantZip: ['', [Validators.required]],
      TenantAddr1: ['', [Validators.required]],
      TenantAddr2: ['', []],
      Position: ['', []],
      ContactTelNo: ['', [
        Validators.required,
        Validators.pattern('^[0-9-]+$')
      ]],
      SpecYN1: [false, []],
      SpecYN2: [false, []],
      SpecYN3: [false, []],
      SpecYN4: [false, []],
      SpecYN5: [false, []],
      SpecYN6: [false, []],
      Comments: ['', []]
    });

    // 정보 푸시 및 개인정보 동의 폼
    this.submitForm = this.fb.group({
      EmailYN: [false, []],
      SmsYN: [false, []],
      KakaoYN: [false, []],
      PublicYN: [false, []],
    });
    
    this.valid = {...this.joinForm.controls, ...this.entForm.controls};

    // 로그인 아이디 타이핑시 중복체크를 다시하도록
    this.joinForm.get('LoginId').valueChanges.subscribe(res => {
      this.duplicate = undefined;
    });
  }

  // 주소검색 
  srchAddr(dat: string) {
    this.presentModal(dat);
  }

  // 주소검색 모달
  async presentModal(dat: string) {
    if (dat=='tenant') {
      const modal = await this.modalCtrl.create({
        component: SrchAddrComponent,
      });
      let res = modal.onWillDismiss();
      res.then(data => {
        if (data.data) {
          this.entForm.controls.TenantZip.setValue(data.data.zonecode);
          this.entForm.controls.TenantAddr1.setValue(data.data.address);
          this.entForm.controls.Sigungucode.setValue(data.data.sigunguCode);
        }
      });
      return await modal.present();
    }
    else {
      const modal = await this.modalCtrl.create({
        component: SrchAddrComponent,
      });
      let res = modal.onWillDismiss();
      res.then(data => {
        if (data.data) {
          this.joinForm.controls.ZipCode.setValue(data.data.zonecode);
          this.joinForm.controls.Addr1.setValue(data.data.address);
        }
      });
      return await modal.present();
    }
  }

  // 아이디 중복 체크
  dupleCheck() {
    let data: any = {};
    data.LoginId = this.joinForm.controls.LoginId.value;

    if (this.joinForm.controls.LoginId.valid) {
      this.checkedId = data.LoginId;

      this.restApi.duplicateCheck(data, (res: any) => {
        if (res.header.status === CONSTANT.HttpStatus.OK) {
          if (res.body.docs[0].result == 1) {
            this.duplicate = true;
          } else {
            this.duplicate = false;
          }
        }
      },
      error => {
        console.error(error);
      });
    }
  }

  // 휴대폰 본인인증
  authMob(){
    if (this.verifiedPersonalInfo) {
      this.verifiedPersonalInfo = false;
      return;
    }

    if(this.entForm.controls.CustNm.value == '') {
      this.toastCtrl.presentToast('이름을 입력하세요.');
      return true;
    }    

    if(this.entForm.controls.Mobile.value == '') {
      this.toastCtrl.presentToast('휴대폰번호를 입력하세요.');
      return true;
    }
    
    const mob = this.entForm.controls.Mobile;
    const result = mob.value.replaceAll('-', '').replaceAll(' ', '');
    const regExp = /^[0-9-]{10,11}$/;
    mob.setValue(result);

    // 휴대폰번호 중복체크 로직
    let mobileData: any = {};
    mobileData.CustNm = this.entForm.controls.CustNm.value;
    mobileData.Mobile = result;

    if (!regExp.test(result)) {
      this.toastCtrl.presentToast('휴대폰번호 형식에 맞지 않는 번호입니다.');
      return;
    }

    this.restApi.duplicateMobileCheck(mobileData, (res: any) => {
      if (res.header.status === CONSTANT.HttpStatus.OK) {
        // 휴대폰번호 중복 처리
        if (res.body.docs[0].result > 0) {
          this.duplicateMobile = true;

          this.toastCtrl.presentToast('이미 등록된 휴대폰번호 입니다.');
          return;
        } 
        // 휴대폰번호가 중복이 아니고 정규식이 맞다면 인증 처리
        else {
          this.duplicateMobile = false;

          if (rootScope.isMobile) {
            // var userCode = 'imp86263784';                   // 가맹점 식별코드
            var userCode = 'imp26101278';                   // 가맹점 식별코드
            var data = {
              carrier: '',                                  // 통신사
              merchant_uid: 'mid_' + new Date().getTime(),  // 주문번호
              company: 'SIOT',                              // 회사명
              name: this.entForm.controls.CustNm.value,    // 이름
              phone: this.entForm.controls.Mobile.value,   // 전화번호
            };
            var titleOptions = {
              text: '아임포트 코르도바 테스트',               // 타이틀
              textColor: '#ffffff',                         // 타이틀 색
              textSize: '20',                               // 타이틀 크기
              textAlignment: 'left',                        // 타이틀 정렬 유형
              backgroundColor: '#344e81',                   // 타이틀 배경색
              show: true,                                   // 타이틀 유무
              leftButtonType: 'back',                       // 왼쪽 버튼 유형
              leftButtonColor: '#ffffff',                   // 왼쪽 버튼 색
              rightButtonType: 'close',                     // 오른쪽 버튼 유형
              rightButtonColor: '#ffffff',                  // 오른쪽 버튼 색
            };
        
            // 4. 아임포트 코르도바 파라미터 정의
            var params = {
              userCode: userCode,                           // 4-1. 가맹점 식별코드 정의
              data: data,                                   // 4-2. 결제 데이터 정의
              titleOptions: titleOptions,                   // 4-3. 본인인증 창 헤더 옵션 정의
              callback: response => {                       // 4-3. 콜백 함수 정의
                if (response.imp_success == 'true') {
                  console.log(response);
                  this.verifyPersonalInfo(true);
                } else {
                  this.verifyPersonalInfo(false);
                }
              },
            };
      
            // 5. 본인인증 창 호출
            IamportCordova.certification(params);      
            return;
          } else {
            //결제초기화 : 상점ID
            // IMP.init("imp31731325");              

            // IMP.init("imp86263784"); 
            IMP.init("imp26101278"); 
            console.log(IMP);
            setTimeout(() => {

              IMP.certification({ 
                merchant_uid: this.setMerchantUID()
                ,name: this.entForm.controls.CustNm.value
                ,phone: this.entForm.controls.Mobile.value           
              }, certifyResult => {
                console.table(certifyResult);
                
                if (certifyResult.success) {
                  this.verifyPersonalInfo(true);
                } else {
                  this.verifyPersonalInfo(false);
                  this.toastCtrl.presentToast("본인인증에 실패하였습니다.(" + certifyResult.error_msg + ")");
                }
              });
            }, 1000)
          }
        } 
      }
    },
    error => {
      console.error(error);
      this.toastCtrl.presentToast('휴대폰 번호 중복체크에 오류가 발생하였습니다.');
    });
  }

  setZero = function(val) {
    return val > 10 ?  '' + val : '0' + val ;
  };  

  setMerchantUID = function() {
    var d = new Date();
    var rndNum = Math.floor(10 + Math.random() * d.getTime());
    return "CID_" + (d.getFullYear() + "").substring(2, 4) + "" + this.setZero(d.getMonth() + 1) + "" + this.setZero(d.getDate()) + "" + this.setZero(d.getHours()) + "" + this.setZero(d.getMinutes()) + "_" + rndNum;;
  }

  verifyPersonalInfo = function (param) {
    if (param) {
      this.toastCtrl.presentToast('인증 되었습니다.');
      document.getElementById("CustNm").setAttribute("disabled", "");
      document.getElementById("Mobile").setAttribute("disabled", "");
      document.getElementById("strVerify").style.display = "none";
      document.getElementById("strReVerify").style.display = "block";
    } else {
      this.toastCtrl.presentToast('인증되지 않았습니다.');
    }

    this.verifiedPersonalInfo = param;
  }

  validCheck() {
    
    // ID 중복체크 여부
    if ((!this.userInfo.id || this.custType == 'E') && (this.duplicate || this.duplicate == undefined)) {
      this.toastCtrl.presentToast('ID 중복확인을 해주세요.')
      return true;
    }

    // sns login 시에 필수값 제거
    if(this.userInfo.id){
      // 개인회원은 전체 필수 값 제거 후 자동가입
      if(this.custType == 'P'){
        for(const key in this.joinForm.controls){
          this.joinForm.controls[key].clearValidators();
          this.joinForm.controls[key].updateValueAndValidity();
        }
      // 중개회원은 비밀번호 필수만 제거
      }else{
        this.joinForm.controls.Password.clearValidators();
        this.joinForm.controls.rePassword.clearValidators();
        this.joinForm.controls.Password.updateValueAndValidity();
        this.joinForm.controls.rePassword.updateValueAndValidity();
      }
    }

    // 개인정보 Form Valid Check
    if (formCheck(this.joinForm.controls)) {
      this.toastCtrl.presentToast('입력란을 확인해주세요.')

      if (this.custType == 'E') {
        // 중개사정보 Form Valid Check
        if (formCheck(this.entForm.controls)) {
          this.toastCtrl.presentToast('입력란을 확인해주세요.')
          return true;
        }
      }
      return true;
    }
    
    
    if (this.custType == 'E') {
      // 중개사정보 Form Valid Check
      if (formCheck(this.entForm.controls)) {
        this.toastCtrl.presentToast('입력란을 확인해주세요.')
        return true;
      }
      
      // 휴대폰번호 인증 체크
      if (this.joinForm.controls.JoinPath.value == 'DOMESTIC' && (this.duplicateMobile || this.duplicateMobile == undefined || !this.verifiedPersonalInfo)) {
        this.toastCtrl.presentToast('휴대폰번호를 인증요청 해주세요.')
        return true;
      }

      // 전문분야를 한 배열에 입력
      const specs = this.entForm.value;
      let specCnt = 0;
      for (const key in specs) {
        if (/YN/.test(key) && specs[key]) {
          specCnt++;
          break;
        }
      }

      // 선택된 전문분야 갯수
      if (specCnt == 0) {
        this.pickSpec = true;
        this.toastCtrl.presentToast('전문분야를 선택해주세요.');
        return true;
      }else if(!this.photo){
        this.toastCtrl.presentToast('사진을 선택해주세요.');
        return true;
      }else if(this.joinForm.controls.JoinPath.value != 'ABROAD' && this.entForm.controls.TenantType.value == 'tenant'){
        if(!this.license){
          this.toastCtrl.presentToast('중개사 등록증을 선택해주세요.');
          return true;
        }else if(!this.biz){
          this.toastCtrl.presentToast('사업자 등록증을 선택해주세요.');
          return true;
        }
      }
    }
    
    return false;
  }

  submit() {
    if (this.validCheck()) {
      return;
    }
    
    const data = this.prepData();
    this.restApi.signUp(data, (res : any) =>{
      if(res.header.status === CONSTANT.HttpStatus.OK){
        if(res.body.docCnt > 0){
          const info = res.body.docs[0];
          if (info.result == 10) {
            this.toastCtrl.presentToast('등록된 사업자입니다.');
            return;
          } else if (info.result == 0) {
            this.toastCtrl.presentToast('잘못된 접근입니다.');
            return;
          } else {
            this.toastCtrl.presentToast('가입 되었습니다.');
            if(this.from){
              let url : string;
              if(this.keyId){
                // url = '/my/' + this.keyId;
                // this.router.navigateByUrl(url);
                location.href = 'https://play.google.com/store/apps/details?id=com.softzion.anybang';
              }else{
                url = '';
                this.router.navigateByUrl(url);
              }
            }else{
              this.router.navigateByUrl('/home/login');
            }
          }
        }
      }
    },
    (error: any) => {
      console.error(error);
    });
  }

  prepData(){
    // 개인정보와 알림서비스 입력 통합
    let data: any = { ...this.joinForm.value, ...this.submitForm.value };
    
    // 기업회원 가입일 경우 기업정보도 통합
    if (this.custType == 'E') {
      data = { ...data, ...this.entForm.value };
      // 증명사진, 사업자 등록증, 중개등록증 사진 통합
      data.uploadFileList = [
        this.photo, this.license, this.biz
      ]
      data.IdName = 'CustId';
      data.tableNm = 'anycustomer';
    }
    
    // 개인정보 및 레터수신 여부 병합
    data = booleanToYN(data);
    data.Specs = [data.SpecYN1, data.SpecYN2, data.SpecYN3, data.SpecYN4, data.SpecYN5, data.SpecYN6];
    data.CustType = this.custType;
    data.mapCode = 'SignIn.signUp';

    // sns 로그인할 경우
    if(this.userInfo.id){
      data.SimpleLoginType = this.userInfo.sns;
      data.TokenId = this.userInfo.id;
    }

    return data;
  }

  agree(){
    for(const key in this.submitForm.controls){
        this.submitForm.controls[key].setValue(!this.agreed);
      }
    this.agreed = !this.agreed;
  }

  addValid(who : boolean){
    this.tenantTy = who;
    if(this.joinTy && who){
      this.entForm.controls.TradeNo.setValidators([
        Validators.required,
        Validators.pattern('^[0-9-]+$')
      ]);
      this.entForm.controls.TradeNo.updateValueAndValidity();
      this.entForm.controls.BizRegNo.setValidators([
        Validators.required,
        Validators.pattern('^[0-9-]+$')
      ]);
      this.entForm.controls.BizRegNo.updateValueAndValidity();
    }else{
      this.entForm.controls.TradeNo.clearValidators();
      this.entForm.controls.TradeNo.updateValueAndValidity();
      this.entForm.controls.BizRegNo.clearValidators();
      this.entForm.controls.BizRegNo.updateValueAndValidity();
    }
  }

  whereFrom(){
    const isDom = this.joinForm.controls.JoinPath.value == 'DOMESTIC' ? true : false;
    const isTen = isDom && this.entForm.controls.TenantType.value == 'tenant' ? true : false;
    this.addValid(isTen);
    this.joinTy = isDom;
    if(isDom){
      this.entForm.controls.Mobile.setValidators([
        Validators.required,
        Validators.pattern('^[0-9-]{10,11}$')
      ]);
      this.entForm.controls.Mobile.updateValueAndValidity();
    }else{
      this.entForm.controls.Mobile.setValue('');
      this.entForm.controls.Mobile.clearValidators();
      this.entForm.controls.Mobile.updateValueAndValidity();
    }
  }
  
}
