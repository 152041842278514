import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'custtype'
})
export class CusttypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value == 'P' ? '개인회원' : '중개회원';
  }
}
