import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appYoutubeEmbed]'
})
export class YoutubeEmbedDirective {

  constructor(private el : ElementRef) { }

  @HostListener('keyup')
  embedUrl(){
    const url : string = this.el.nativeElement.value;
    const keyIdx = url.lastIndexOf('/');
    const result = 'https://www.youtube.com/embed/' + url.substr(keyIdx + 1);
    this.el.nativeElement.value = result;
  }

}
