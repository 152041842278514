import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ActionSheetController, Platform } from '@ionic/angular';
import { rootScope } from 'src/app/global/global';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { StaticVariableService } from 'src/app/service/static-variable.service';

@Component({
  selector: 'app-take-photo',
  templateUrl: './take-photo.component.html',
  styleUrls: ['./take-photo.component.scss'],
})
export class TakePhotoComponent implements OnInit, OnChanges {

  public result;
  @ViewChild('fileInput') fileInput : ElementRef;
  @Input('grpId') grpId;
  @Input('btn') btn;

  @Output() fileChange = new EventEmitter();
  @Input() 
  get file (){
    return this.result;
  }

  set file(value){
    this.result = value;
    this.fileChange.emit(this.result);
  }

  constructor(public camera : Camera, private fileUp : FileUploadService, public actSheetCtrl : ActionSheetController, public platform : Platform, public StaticVariable : StaticVariableService) { }

  ngOnInit() {}

  ngOnChanges(){
    if(this.file){
      this.convertLink();
    }
  }

  // web - app 사진첨부 분기처리
  getPhoto(){
    if(rootScope.isMobile){
      this.selectImage();
    }else{
      this.fileInput.nativeElement.click();
    }
  }
  
  // app에서 첨부파일 선택
  pickImage(sourceType : number){
    const options: CameraOptions = {
      quality: 50,
      sourceType: sourceType,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      saveToPhotoAlbum : true,
      destinationType : 0
    }
    
    // 한장만 첨부가능
    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      let file = [];
      file.push('data:image/jpeg;base64,' + imageData);
      this.fileUp.uploadFiles(file).then(res => {
        res[0].grpId = this.grpId;
        // 기존 파일에서 수정시, 기존 파일삭제 및 DB DelYN처리 후 수정된 파일로 교체
        if(this.file){
          this.deletePhoto(res[0]);
        }else{
          this.file = res[0];
        }
        // this.camera.cleanup();
      });
    }, (err) => {
      // Handle error
    });
  }

  // app에서 카메라촬영 - 사진 갤러리 선택
  async selectImage() {
    const actionSheet = await this.actSheetCtrl.create({
      header: "사진 선택",
      buttons: [{
        text: '갤러리',
        handler: () => {
          this.pickImage(this.camera.PictureSourceType.PHOTOLIBRARY);
        }
      },
      {
        text: '카메라',
        handler: () => {
          this.pickImage(this.camera.PictureSourceType.CAMERA);
        }
      },
      {
        text: '취소',
        role: 'cancel'
      }
      ]
    });
    await actionSheet.present();
  }

  deletePhoto(newFile? : any){
    const item = this.file;

    // Web일 경우 input 태그의 value를 제거해 줘야 같은 이름의 파일을 다시 올릴수 있음 
    if(!rootScope.isMobile){
      this.fileInput.nativeElement.value = null;
    }
    
    this.fileUp.onFileDelete(item, res =>{
      if(newFile){
        this.file = newFile;
      }else{
        this.file = undefined;
      }
    },
    error =>{
      console.error(error);
    });
  }

  // web에서 사진 추가시 
  upload(e){
    const files = e.target.files;
    this.fileUp.uploadFiles(files).then(res => {
      res[0].grpId = this.grpId;
      // 기존 파일에서 수정시, 기존 파일삭제 및 DB DelYN처리 후 수정된 파일로 교체
      if(this.file){
        this.deletePhoto(res[0]);
      }else{
        this.file = res[0];
      }
    });
  }

  // 첨부파일 변환과정
  convertLink(){
    const item = this.file;
    if(item.fileId){
      let obj = {
        id : item.fileId,
        isLoaded : true,
        link : this.StaticVariable.getFileDownloadUrl(item.PhysicalFileNm),
        fileName : item.LogicalFileNm,
        size : item.FileSize,
        fileKey : item.PhysicalFileNm,
        path : item.PhysicalPath,
        downloadCnt : item.DownloadCnt,
        grpId : item.GrpId
      }
      this.file = obj;
    }
  }
  
}
