import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';
import { rootScope } from 'src/app/global/global';

@Component({
  selector: 'app-stars-up',
  templateUrl: './stars-up.component.html',
  styleUrls: ['./stars-up.component.scss'],
})
export class StarsUpComponent implements OnInit {

  public result : any;
  public stars : Array<boolean>;
  public activate : boolean = true;
  public rootScope = rootScope;

  @Input('starsFor') starNm : string;
  @Input('readOnly') read : boolean;
  @Output() dataChange = new EventEmitter();

  @Input() 
  get data (){
    return this.result;
  }

  set data(value){
    this.result = value;
    this.dataChange.emit(this.result);
  }

  constructor(public platform : Platform) { 
    this.stars = [
      false, false, false, false, false
    ]
  }

  ngOnInit() {
    if(this.result && this.result > 0){
      this.activate = false;
    }
    for (let i = 0 ; i < 5 ; i++) {
      if (i < this.result) {
        this.stars[i] = true;
      }else{
        this.stars[i] = false;
      }
    }
  }

  hoverStar(th : number){
    if(this.read){
      return;
    }else if(!this.activate){
      return;
    }
    for (let i = 0 ; i < 5 ; i++) {
      if (i < th) {
        this.stars[i] = true;
      }else{
        this.stars[i] = false;
      }
    }
  }

  setStar(th : any){
    if (this.read) {
      return;
    }
    this.activate = !this.activate;
    this.data = th;
  }

  touchStar(th : number){
    if(this.read){
      return;
    }
    for (let i = 0 ; i < 5 ; i++) {
      if (i < th) {
        this.stars[i] = true;
      }else{
        this.stars[i] = false;
      }
    }
    this.data = th;
  }
  
}
