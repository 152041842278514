import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-srch-addr',
  templateUrl: './srch-addr.component.html',
  styleUrls: ['./srch-addr.component.scss'],
})
export class SrchAddrComponent implements OnInit, AfterViewInit {

  @ViewChild('addrModal') addrModal : ElementRef;

  constructor(public modalCtrl : ModalController) { 
  }
  
  ngAfterViewInit(){
    new daum.Postcode({
      oncomplete: (res : any) => {
        this.modalCtrl.dismiss(res);
      },
      width: '100%',
      maxSuggestItems : 5
    }).embed(this.addrModal.nativeElement);
  }
  ngOnInit() {
  }

}
