import { AbstractControl, Validators } from "@angular/forms";
declare global {
  interface Window {
    response(res : any) : Promise<any>;
  }
}

String.prototype.fillStr = function (str, len) {
  var orgStr = this.toString();
  while (orgStr.toString().length < len) {
    orgStr = str + orgStr;
  }
  return orgStr;
};
String.prototype.left = function (len) {
  var orgStr = this.toString();
  return orgStr.substring(0, len);
};

String.prototype.replaceAll = function (target, replacement) {
  return this.split(target).join(replacement);
};

export namespace MAIN{
  export let APP_NAME : string = 'demo3';
}

export namespace rootScope{
  export let currentLanguage = '';
  export let navbarToggle : boolean;
  export let isMobile : boolean;

  export let gVariable : any = {};

  export function setUserSession(data : any){
    window.sessionStorage['isLoggedIn'] = 'Y';
    window.sessionStorage['loginUserId'] = data.loginId;
    window.sessionStorage['loginUserIdx'] = data.userId;
    window.sessionStorage['loginSysAdminYN'] = data.sysAdminYN;
    window.sessionStorage['loginUserName'] = data.userName;
    window.sessionStorage['loginUserGbn'] = data.userGroupId;
    window.sessionStorage['loginUserDeptNm'] = data.DeptNm;
    window.sessionStorage['loginUserDeptId'] = data.DeptId;
    window.sessionStorage['loginComGbn'] = data.company;
    window.sessionStorage['loginUserType'] = data.userType;
    window.sessionStorage['loginUserEmail'] = data.email;
    window.sessionStorage['loginTenantId'] = data.TenantId == null ? 0 : data.TenantId;
    window.sessionStorage['loginTenantName'] = data.TenantName;
    window.sessionStorage['loginTenantDir'] = data.TenantDir == null ? null : data.TenantDir;
    window.sessionStorage['loginEmail'] = data.Email;
    window.sessionStorage['loginTelNo'] = data.TelNo;
    window.sessionStorage['loginMobile'] = data.Mobile;
    window.sessionStorage['noHeader'] = data.noHeader == null ? false : data.noHeader;
    window.sessionStorage['mainPage'] = data.mainpage == null ? 'app.dashboard' : data.mainpage;
    window.sessionStorage['mainPageSrch'] = data.mainpagesrch == null ? '' : data.mainpagesrch;
    rootScope.gVariable.UserId = window.sessionStorage['loginUserIdx'];
    rootScope.gVariable.TenantId = window.sessionStorage['loginTenantId'];
    rootScope.gVariable.TenantName = window.sessionStorage['loginTenantName'];
    rootScope.gVariable.TenantDir = window.sessionStorage['loginTenantDir'];
    rootScope.gVariable.SysAdminYN = window.sessionStorage['loginSysAdminYN'];
    rootScope.gVariable.Email = window.sessionStorage['loginEmail'];
    rootScope.gVariable.TelNo = window.sessionStorage['loginTelNo'];
    rootScope.gVariable.UserName = window.sessionStorage['loginUserName'];
    rootScope.gVariable.userType = window.sessionStorage['loginUserType'];
    rootScope.gVariable.DeptNm = window.sessionStorage['loginUserDeptNm'];
    rootScope.gVariable.DeptId = window.sessionStorage['loginUserDeptId'];
    rootScope.gVariable.Mobile = window.sessionStorage['loginMobile'];
    rootScope.gVariable.noHeader = window.sessionStorage['noHeader'];
    rootScope.gVariable.mainPage = window.sessionStorage['mainPage'];
    rootScope.gVariable.mainPageSrch = window.sessionStorage['mainPageSrch'];
    if (data.userkey) {
      window.sessionStorage['userkey'] = data.userkey;
      rootScope.gVariable.userKey = window.sessionStorage['userkey'] + '-' + window.sessionStorage['loginTenantId'];
    }
  }

  export function setLocalStorage(param : any){
    for(const key in param){
      localStorage.setItem(key, param[key]);
      rootScope.gVariable[key] = param[key];
    }
  }
  export function setSessionStorage(param : any){
    for(const key in param){
      sessionStorage.setItem(key, param[key]);
    }
  }

  export function destroyLocalStorage(){
    localStorage.clear();
    sessionStorage.clear();
  }

  export function changeLanguage(langKey){
    // $translate.use(langKey);
    rootScope.currentLanguage = langKey;
    window.sessionStorage.setItem("currentLanguage", langKey);
  }

  export function setGVariable(a, b){}


  // 엑셀 설정
  // 다중 시트 기능 추가 2020-01-20 hansol
  export function excelDown(exportData, fileName, sheetNames?) {


  }

  //yyyy-mm-dd > new Date 형태로 변환
  export function getDateFormat(dateStr) {
    var dateArr;

    if (typeof(dateStr) == "undefined") {
      return null;
    }
    else {
      dateArr = dateStr.split("-");
    }

    if (dateArr.length == 3) {
      return new Date(dateArr[0], parseInt(dateArr[1]) - 1, dateArr[2]);
    }
    else {
      return null;
    }
  }
}



export function isNullOrEmpty(value? : any) : boolean{
  return value === "" || value === " " || value === '0.00' || value === null || value === undefined || (value != null && typeof value === "object" && !Object.keys(value).length);
}

export function setBreakPoint(obj) {
  var size = ['xs', 'sm', 'md', 'lg', 'xl'];
  for(var i = 0; i < size.length; i++) {
    if(obj != "" && size[i] == obj) {
      obj =  "";
      for(var j = 0; j < i; j++) obj +=  size[j] + " ";
    }
  }
  return obj;
}

export function getSearchConditionStr(count, columnMap, srch) {
  var countStr = count? count : 0;

  var searchConditionStr = '<b>검색결과 <font color=red>' + Number(countStr).toLocaleString('en') + '</font>건</b> ';

  var str = [];
  for (var key in srch) {
    if (key == 'page' || key == 'viewSize' || key == 'tableNm' || key == 'sortDirect' || key == 'sortColumn' || key == 'userkey' || key == 'selectType' || key == 'srchTenantId') {
      continue;
    }

    var key2 = key.replaceAll('VarcharLike', 'Varchar');
    var name = key2;

    var value = srch[key];

    if (columnMap[key2]) {
      name = columnMap[key2].ColumnLogicalNm;

      if (columnMap[key2].ColumnLogicalDataType == "50") {
        if (columnMap[key2].CodeList) {
          columnMap[key2].CodeList.forEach(function (v) {
            if (v.Code == value) {
              value = v.CodeValue;
              return;
            }
          });
        }
      }

      str.push(name + ':' + value);
    }
  }

  searchConditionStr += str.join(',');

  return searchConditionStr;
}

export function makeDateUUID() {

  // ex : 14 61 20 90 04 27 72 7; : length = 15
  // [년, 월, 일](4) + 요일(1) + 시간(2) + 분(2) + 초(2) + 밀리세컨드(2) + 랜덤숫자(2);
  var date = new Date();
  var year = date.getFullYear();
  var month = String(date.getMonth()+1);
  var day = String(date.getDate());
  var gday = String(date.getDay());
  var hh = String(date.getHours()).fillStr(0,2);
  var mm = String(date.getMinutes()).fillStr(0,2);
  var dd = String(date.getMinutes()).fillStr(0,2);
  var ss = String(date.getSeconds()).fillStr(0,2);
  var ms = String(date.getMilliseconds()).fillStr(0,2).left(2);
  var rand = String(Math.floor(Math.random() * 100)).fillStr(0,2);
  var uuid = year + Number(month) + Number(day) + gday + hh + dd + ss+ ms + rand;
  if(uuid.length != 15) {
    var size = 15 - uuid.length;
    uuid += String(Math.floor(Math.random() * 10000000)+1111111).left(size);
  }
  return uuid;
}

// FormBuilder 내의 항목 Valid Check
export function formCheck(form : any){
  let result : boolean = false;
  for (const key in form) {
    form[key].markAsTouched();
    if(!form[key].valid) {
      result = true;
    }
  }
  return result;
}

export function booleanToYN(obj : any) : any{
  for(const key in obj){
    if(/YN/.test(key)){
      obj[key] = obj[key] ? 'Y' : 'N';
    }
  }
  return obj;
}

export class ValidatorsExt extends Validators{
  static match(form : AbstractControl) : any{
    if(form.root.value['Password'] == form.value){
      return null;
    }else{
      return {match : false};
    }
  }
}

export let isMobile : boolean;
