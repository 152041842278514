import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GeolocationService } from '@ng-web-apis/geolocation';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-addr-srch',
  templateUrl: './addr-srch.component.html',
  styleUrls: ['./addr-srch.component.scss'],
})
export class AddrSrchComponent implements OnInit {

  result : any;
  private coords : any;
  public rowAddr : string;

  @Input() 
  get data (){
    return this.result;
  }

  set data(value){
    this.result = value;
    this.dataChange.emit(this.result);
  }

  @Output() dataChange = new EventEmitter;

  constructor(public gps : GeolocationService, private geolocation : Geolocation, public platform : Platform) { 

    // 사용자의 gps 파악
    gps.subscribe(data => {
      this.coords = data.coords;
    });

    // if(platform.is('android')){
    //   platform.ready().then(() =>{
    //     geolocation.getCurrentPosition().then(res =>{
    //       this.coords = res.coords;
    //     });
    //   })
    // }else{
    // }
  }

  ngOnInit() {}

  srchAddr(el : any){
    
    // 현재 위치 저장
    const center = new kakao.maps.LatLng(this.coords.latitude, this.coords.longitude);

    const mapOption = {
      center : center,
      level : 3
    };

    // 맵 크기
    el.style.height = '250px';
    
    // 맵 객체 생성
    let map = new kakao.maps.Map(el, mapOption);


    // 주소검색 객체 생성
    const geocoder = new kakao.maps.services.Geocoder();

    // 결과값으로 받은 위치를 마커로 표시합니다
    let marker = new kakao.maps.Marker({
      position: center
    });

    if(this.rowAddr != undefined && this.rowAddr != ''){
      geocoder.addressSearch(this.rowAddr, (result, status) => {
        if (status === kakao.maps.services.Status.OK) {
          const data = result[0];
          const coords = new kakao.maps.LatLng(data.y, data.x);
          map.setCenter(coords);
          marker.setPosition(coords);
          marker.setMap(map);
        }
      });
    }else{
      
      this.setAddress(geocoder, center);
      map.setCenter(center);
      marker.setMap(map);
    }
    
    // 지도가 이동, 확대, 축소로 인해 중심좌표가 변경되면 마지막 파라미터로 넘어온 함수를 호출하도록 이벤트를 등록합니다
    kakao.maps.event.addListener(map, 'center_changed', () => {

      // 지도의 중심좌표를 얻어옵니다 
      let latlng = map.getCenter(); 

      this.setAddress(geocoder, latlng);
      marker.setPosition(latlng);
      marker.setMap(map);
    });
  }

  // 주소 양식에 맞게 주입
  setAddress(geocoder, coords : any){
    let dong : string;
    // 좌표로 주소 검색
    geocoder.coord2Address(coords.La, coords.Ma, res =>{
      let data : any;
      
      // 모든 좌표가 도로명 주소와 1:1 매치가 되지 않아서 지번주소로 입력
      if(res[0].road_address){
        data = res[0].road_address;
        dong = data.region_3depth_name + ' ' + data.road_name + ' ' + data.main_building_no + (data.sub_building_no != '' ? '-' + data.sub_building_no : '');
      }else{
        data = res[0].address;
        dong = data.region_3depth_name + ' ' + data.main_address_no + (data.sub_address_no != '' ? '-' + data.sub_address_no : '');
      }
      this.data.Addr1 = data.address_name;
      this.data.Sido = data.region_1depth_name;
      this.data.Gungu = data.region_2depth_name;
      this.data.Dong = dong;
    });
  }

}
