import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { Keyboard } from "@ionic-native/keyboard/ngx";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent{

  public custType : string;
  public name : string;
  public phone : string;  
  @ViewChild('visible') visible : ElementRef;

  constructor(public session : SessionService, public router : Router, public keyboard : Keyboard, public render : Renderer2) { 
    session.user.subscribe(data => {
      this.name = data.CustNm;
      this.phone = data.Mobile;
      this.custType = data.CustType;
    });

    this.keyboard.onKeyboardDidHide().subscribe(() => {
      this.setVision('block');
    });
    
    this.keyboard.onKeyboardDidShow().subscribe(() => {
      this.setVision('none');
    });
  }
  
  home(){
    if ((localStorage.getItem('CustId') == '' || localStorage.getItem('CustId') == undefined) && (sessionStorage.getItem('CustId') == '' || sessionStorage.getItem('CustId') == undefined)) {
      this.router.navigateByUrl('home');
    } else if (localStorage.getItem('CustType') == 'P' || sessionStorage.getItem('CustType') == 'P') {
      this.router.navigateByUrl('personal');
    } else {
      this.router.navigateByUrl('enterprise');
    }
  }

  mypage() {
    if ((localStorage.getItem('CustId') == '' || localStorage.getItem('CustId') == undefined) && (sessionStorage.getItem('CustId') == '' || sessionStorage.getItem('CustId') == undefined)) {
      this.router.navigateByUrl('home/login');
    }else{
      this.router.navigateByUrl('home/menu');
    }
  }

  setVision(what){
    if(this.visible.nativeElement){
      this.render.setStyle(this.visible.nativeElement, 'display', what);
    }
  }
}
