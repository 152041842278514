import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CONSTANT } from 'src/assets/global-constant';
import { isNullOrEmpty, rootScope } from '../global/global';
import { RestApiService } from './rest-api.service';
import { SessionService } from './session.service';
import { StaticVariableService } from './static-variable.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private userKey = this.session.user.value.userkey + "-" + this.session.user.value.TenantId;
  
  constructor(public platform : Platform, public restApi : RestApiService, public StaticVariable : StaticVariableService, private session : SessionService, private http : HttpClient) { }

  makeData(D : any) {
    D.userkey = this.session.user.value.userkey + "-" + this.session.user.value.TenantId;
    return D;
  }

  sendPost(url : string, reqData : any, callback, callback2?) {
    this.http.post(url, reqData, this.StaticVariable.getRequestActions.defaultAction()).subscribe(data => {
      callback(this.makeResultData(data), reqData.mapcode);
    }, function(error) {
      callback2(error, reqData.mapcode);
    });
  }

  makeResultData(D){
    let result : any = {};
    if (D == null || D.responseVO == null) return result;
    result.header = D.responseVO.header;
    result.body = D.responseVO.body;
    return result;
  }
  
  uploadFiles(files : Array<any>) : Promise<any>{
    return new Promise((resolve, rejects) => {

      let formData = new FormData;
      if(rootScope.isMobile){
        files.forEach((item, idx) => {
          let blob = this.dataURItoBlob(item);
          formData.append('photo' + idx, blob);  
        });
      }else{
        for(let i = 0; i < files.length; i++){
          formData.append(files[i].name, files[i]);
        }
      }
      console.log(formData);

      this.uploadFile(formData, res=>{
        if(res.header.status === CONSTANT.HttpStatus.OK){
          let links = [];
          res.body.docs.forEach(each =>{
            let url : string;
            // 비회원 파일 업로드(회원가입)
            if(isNullOrEmpty(this.session.user.value)){
              url = this.StaticVariable.getUrl("/openapi/attach/temp/" + each.tmpFileKey + "/download.do?fileName=" + each.encFileName);
            }else{
              url = this.StaticVariable.getUrl("/attach/temp/" + each.tmpFileKey + "/download.do?fileName=" + each.encFileName);
            }
            let obj : any = {};
            obj.fileName = each.encFileName;
            obj.isLoaded = true;
            obj.tmpFileKey = each.tmpFileKey;
            obj.typeId = each.typeId;
            obj.path = each.path;
            // obj.RelateId = vm.relateId;
            obj.link = url;
            obj.delState = '';
            links.push(obj);
          });
          
          resolve(links);
        }
      },
      error => {
        rejects(error);
        console.error(error);
      });
    });
  }
  
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});
  }

  uploadFile(data : FormData, callback1 : Function, callback2? : Function){
    let url : string;
    // 비회원 파일 업로드(회원가입)
    if(isNullOrEmpty(this.session.user.value)){
      url = this.StaticVariable.getUrl('/openapi/attach/temp/upload.do');
    }else{
      url = this.StaticVariable.getUrl('/attach/temp/upload.do');
    }
    const xmlHttp = new XMLHttpRequest;
    xmlHttp.onload = () => {
      const result = JSON.parse(xmlHttp.response).responseVO;
      callback1(result);
    }
    
    xmlHttp.open("POST", url);
    xmlHttp.send(data);
  }

  onFileDelete(data, callback1, callback2?){
    let url;
    if(data.id){
      url = this.StaticVariable.getUrl('/attach/file/delete.json');
      data.fileId = data.id;
    }else{
      // 비회원 파일 업로드(회원가입)
      if(isNullOrEmpty(this.session.user.value)){
        url = this.StaticVariable.getUrl('/openapi/attach/temp/delete.json');
      }else{
        url = this.StaticVariable.getUrl('/attach/temp/delete.json');
      }
      data.attachId = data.tmpFileKey;
    }
    this.sendPost(url, this.makeData(data), function(result){ callback1(result); },function(result){ callback2(result); }); 
  }

}
