import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from "./component/shared.module";

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

// Material
import { MatDialogModule } from "@angular/material/dialog";

import { AngularFireModule } from "@angular/fire";
import { fireConfig } from "./global/firebase-config";
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { Facebook } from "@ionic-native/facebook/ngx";
import { NaverCordovaSDK } from "naver-sdk/ngx";
import { KakaoCordovaSDK } from "kakao-sdk/ngx";
import { SnsLoginService } from './service/sns-login.service';

// plugin
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Network } from "@ionic-native/network/ngx";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AngularFireModule.initializeApp(fireConfig),
    BrowserModule.withServerTransition({ appId: 'serverApp' }), 
    IonicModule.forRoot(
      {
        hardwareBackButton: true
      }
    ), 
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NoopAnimationsModule,
    // FormsModule,ReactiveFormsModule,
    AngularFireModule,
    SocialLoginModule,
    BrowserAnimationsModule,

    // Material
    MatDialogModule
  ],
  providers: [
    Facebook, NaverCordovaSDK, KakaoCordovaSDK, SnsLoginService, GooglePlus,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('76787911109-psentmnf1iv8n3ik0b2b0mvap6naqh92.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2831159653868936')
          },
        ]
      } as SocialAuthServiceConfig
    },  
    StatusBar, SplashScreen, Network
    // File,
    /*  ImagePicker, *//* EmailComposer, */
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
