// module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// component
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { WebHeaderComponent } from "../web/header/header.component";
import { WebFooterComponent } from "../web/footer/footer.component";
import { SrchAddrComponent } from './srch-addr/srch-addr.component';
import { ReputationComponent } from "./reputation/reputation.component";
import { InquiryComponent } from "./inquiry/inquiry.component";
import { ProfileComponent } from "./profile/profile.component";
import { PasswordAuthComponent } from "./password-auth/password-auth.component";
import { FindIdComponent } from "./find-id/find-id.component";
import { FindPasswordComponent } from "./find-password/find-password.component";
import { PropListComponent } from "./prop-list/prop-list.component";
import { PropDetailComponent } from "./prop-detail/prop-detail.component";
import { StarsUpComponent } from './stars-up/stars-up.component';
import { FileAttachHybridComponent } from "./file-attach-hybrid/file-attach-hybrid.component";
import { VideoLinkComponent } from "./video-link/video-link.component";
import { RangeDualComponent } from "./range-dual/range-dual.component";
import { TermsComponent } from './terms/terms.component';
import { NaverCallbackComponent } from "./naver-callback/naver-callback.component";
import { AddrSrchComponent } from "./addr-srch/addr-srch.component";
import { WebMainComponent } from './web-main/web-main.component';
import { MiniHomeComponent } from './mini-home/mini-home.component';
import { SearchBoxComponent } from './search-box/search-box.component';
import { JoinComponent } from './join/join.component';
import { PaginatorComponent } from './paginator/paginator.component';

// pipe
import { MtopPipe } from '../pipe/mtop.pipe';
import { SafeUrlPipe } from '../pipe/safe-url.pipe';
import { PriceUnitPipe } from '../pipe/price-unit.pipe';
import { PriceInterpreterPipe } from '../pipe/price-interpreter.pipe';
import { BuildtypePipe } from '../pipe/buildtype.pipe';
import { CusttypePipe } from '../pipe/custtype.pipe';
import { CommaPipe } from '../pipe/comma.pipe';

// directive
import { BackButtonDirective } from './directive/backbutton.directive';

// plugin
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { TakePhotoComponent } from './take-photo/take-photo.component';
import { Camera } from '@ionic-native/camera/ngx';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { Keyboard } from "@ionic-native/keyboard/ngx";
import { NumberOnlyDirective } from './directive/number-only.directive';
import { KtomDirective } from './directive/ktom.directive';
import { DecimalDirective } from './directive/decimal.directive';
import { YoutubeEmbedDirective } from './directive/youtube-embed.directive';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { WebView } from "@ionic-native/ionic-webview/ngx";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    GalleryModule,
    LightboxModule,
    ClipboardModule,
    MatProgressSpinnerModule
  ],
  declarations: [
    /* Component */
    HeaderComponent, FooterComponent, WebHeaderComponent, WebFooterComponent, SrchAddrComponent,
    ReputationComponent, InquiryComponent, ProfileComponent, PasswordAuthComponent, FindIdComponent,
    FindPasswordComponent, PropListComponent, PropDetailComponent, StarsUpComponent,
    FileAttachHybridComponent, VideoLinkComponent, RangeDualComponent, TermsComponent,
    NaverCallbackComponent, AddrSrchComponent, TakePhotoComponent, WebMainComponent,
    MiniHomeComponent, SearchBoxComponent, JoinComponent, PaginatorComponent,

    /* Pipe */
    MtopPipe, SafeUrlPipe, PriceUnitPipe, PriceInterpreterPipe, BuildtypePipe,
    CusttypePipe, CommaPipe,

    /* Directive */
    BackButtonDirective, NumberOnlyDirective, KtomDirective, DecimalDirective, 
    YoutubeEmbedDirective
  ],
  exports: [
    /* Module */
    MatProgressSpinnerModule,

    /* Component */
    HeaderComponent, FooterComponent, WebHeaderComponent, WebFooterComponent,
    ReputationComponent, InquiryComponent, ProfileComponent, PropListComponent,
    PasswordAuthComponent, FindIdComponent, FindPasswordComponent, PropDetailComponent,
    FileAttachHybridComponent, RangeDualComponent, TermsComponent, NaverCallbackComponent,
    AddrSrchComponent, TakePhotoComponent, WebMainComponent, MiniHomeComponent,
    SearchBoxComponent, JoinComponent, PaginatorComponent,

    /* Pipe */
    MtopPipe, SafeUrlPipe, PriceUnitPipe, PriceInterpreterPipe,
    BuildtypePipe, CusttypePipe, CommaPipe,

    /* Directive */
    BackButtonDirective, NumberOnlyDirective, KtomDirective, DecimalDirective,
  ],
  providers: [
    ImagePicker, Camera, Geolocation, Keyboard, PhotoLibrary, WebView
  ]
})
export class SharedModule { }
