import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {

  constructor(private el : ElementRef) { 
  }
  
  @HostListener('keyup')
  valid(){
    this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9]/, '');
  }

}
