import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Platform, AlertController, IonRouterOutlet, ModalController } from '@ionic/angular';
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SessionService } from './service/session.service';
import { Router } from '@angular/router';
import { NavigationService } from './service/navigation.service';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  NetworkStatus, PluginListenerHandle
} from '@capacitor/core';
import { ToastService } from './service/toast-service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { rootScope } from './global/global';
import { RestApiService } from './service/rest-api.service';
import { CONSTANT } from 'src/assets/global-constant';

const { PushNotifications } = Plugins;
const { SplashScreen, Network } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;  
  urlChecker : BehaviorSubject<any> = new BehaviorSubject({});
  backButtonSub : Subscription;
  networkStatus: any;
  networkListener: PluginListenerHandle;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private session : SessionService,
    private alertController: AlertController,
    private router: Router,
    private navigation: NavigationService,
    private toast : ToastService,
    private location : Location,
    private restApi : RestApiService,
    private modalCtrl : ModalController,
  ) {
    
  }
  
  ngOnInit(): void {
    // localStorage check
    if(localStorage.getItem('CustId')){
      this.session.sessionIn(localStorage);
      this.checkSession();
    }
    
    // device OS 확인
    rootScope.isMobile = this.platform.is('android') && !this.platform.is('mobileweb');
    
    // kakao map 사용 appkey 주입
    this.kakaoInit();

    // device별 분기처리
    if(rootScope.isMobile){
      this.mobileInit();
    }else{
      this.initializeApp();
    }
  }

  initializeApp() {
    if(this.pathCheck()){
     this.sessionCheck();
    }
  }

  // 유효한 회원을 session 서비스에 주입 후 메인화면 이동
  sessionCheck(){
    if (this.session.user.value.CustId != null) {
      const url = this.session.user.value.CustType == 'P' ? '/personal' : '/enterprise';
      this.router.navigate([url], {replaceUrl: true});
    } else {
      this.router.navigate(['/home'], {replaceUrl: true});
    }
  }

  pathCheck(){
    const path = this.location.path();
    if(path.indexOf('callback') == -1 && path.length != 0 && path.indexOf('my') == -1 && path.indexOf('web') == -1){
      return true;
    }else{
      return false;
    }
  }

  // DB에 유효한 회원인지 확인
  async checkSession(){
    const data = {
      CustId : localStorage.getItem('CustId')
    };

    return new Promise((resolve, reject) => {
      this.restApi.checkSession(data, res =>{
        if (res.header.status === CONSTANT.HttpStatus.OK) {
          if (res.body.docs[0].result == 1) {
            this.session.sessionIn(localStorage);
          } else {
            this.session.sessionOut();
            localStorage.clear();
            this.router.navigate(['/home']);
          }
          resolve('');
        }
      }, 
      error =>{
      });
    });
  }

  async presentAlertConfirm() {
    // 이미 알림창이 떠있을 경우를 대비해 중복 생성 방지
    this.alertController.dismiss();

    // 알림창 생성
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: '앱을 종료하시겠습니까?',
      buttons: [{
        text: '취소',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {}
      }, {
        text: '종료',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });
  
    await alert.present();
  }

  kakaoInit(){
    Kakao.init('9300720249bc4f1ce240ef7e6e8e2f06');
  }

  mobileInit(){
    
    this.sessionCheck();
    this.makeSample();
    // Status bar Setting
    this.platform.ready().then(() => {
      // Hardware BackButton EventListener 
      // 첫번째 args의 숫자가 크면 하드웨어 백(이전으로 돌아가기) 기능이 보류되고 두번째 args인 핸들러가 작동한다.
      this.backButtonSub = this.platform.backButton.subscribeWithPriority(1000, async () => {
        const modal = await this.modalCtrl.getTop();
        if(modal){
          modal.dismiss();
        }else if(this.router.url.indexOf('/main') > -1){
          this.presentAlertConfirm();
        }else{
          this.navigation.back();
        }
      });
      
      // Set StatusBar Color
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#000000');

      SplashScreen.hide();
      this.networkSubs();
    });
    
    console.log('Initializing HomePage');
    localStorage.setItem('TargetUrl', '');

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        this.session.setToken(token.value);
        console.log('Push registration success, token: ' + token.value);
      },
    );

    PushNotifications.addListener('registrationError', (error: any) => {
      alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotification) => {
        this.toast.presentToast('TargetUrl:' + notification.notification.data.param1);
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        if (notification.notification.data.param1) {
          this.toast.presentToast('TargetUrl:' + notification.notification.data.param1);
          localStorage.setItem('TargetUrl', notification.notification.data.param1)
        }
      },
    );
  }  

  ngOnDestroy(){
    this.backButtonSub.unsubscribe();
    PushNotifications.removeAllListeners();
    this.networkListener.remove();
  }

  networkSubs(){
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.networkStatus = status;
      if(status.connected === false){
        this.networkMsg();
      }
    });
  }

  async networkMsg(){
    const alert = await this.alertController.create({
      // header: 'Confirm!',
      message: '네트워크가 불안정합니다.',
      buttons: [{
        text: '재시도',
        cssClass: 'secondary',
        handler: () => {
          if(this.networkStatus.connected === false){
            this.networkMsg();
          }else{
            location.reload();
          }
        }
      }, {
        text: '종료',
        handler: () => {
          navigator['app'].exitApp();
        }
      }]
    });
  
    await alert.present();
  }

  // alert를 최소 한번 만들어 두지 않으면 네트워크 불량시 alert 안뜨는 에러
  async makeSample(){
    await (await this.alertController.create({
      // header: 'Confirm!',
      message: '네트워크 불량',
      buttons: [{
        text: '재시도',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {}
      }, {
        text: '종료',
        handler: () => {
          this.networkMsg();
        }
      }]
    })).dismiss();
  }
  
}
