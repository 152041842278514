import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/service/rest-api.service';
import { StaticVariableService } from 'src/app/service/static-variable.service';
import { ToastService } from 'src/app/service/toast-service';
import { CONSTANT } from 'src/assets/global-constant';

@Component({
  selector: 'app-reputation',
  templateUrl: './reputation.component.html',
  styleUrls: ['./reputation.component.scss'],
})
export class ReputationComponent implements OnInit {

  public data : any;
  public lists : any;
  public listCnt : number;
  public test : Document;
  public srch : any;
  public currentPage : number;

  @Input('target') target;
  
  constructor(private restApi : RestApiService, private toastCtrl : ToastService, public el : ElementRef, public StaticVariable : StaticVariableService) { 
    this.srch = {
      IdName : 'CustId',
      tableNm : 'anycustomer',
      uploadFileList : [],
      viewSize : 5,
      sort : 'ApprDate'
    };
  }

  ngOnInit() {
    this.target == 'personal' ? this.getListForP() : this.getListForE();
    console.log("repu", this.target);
  }

  submit(form : any){
    
    console.log(form);
    if(!form.CorrectScore || !form.ProfScore || !form.KindScore){
      this.toastCtrl.presentToast('별점을 선택해주세요.');
      return;
    }
    if (form.Cont == undefined || form.Cont == ' '){
      this.toastCtrl.presentToast('후기를 작성해주세요.');
      return;
    }
    
    if(form.AfterId){
      this.restApi.updateReview(form, res => {
        if(res.header.status === CONSTANT.HttpStatus.OK){
          this.toastCtrl.presentToast('후기가 수정되었습니다.');
          this.getListForP();
        }
      },
      error =>{
        console.error(error);
      });
    }else{
      this.restApi.insertReview(form, res => {
        if(res.header.status === CONSTANT.HttpStatus.OK){
          this.toastCtrl.presentToast('후기가 작성되었습니다.');
          this.getListForP();
        }else{
          this.toastCtrl.presentToast('문제가 발생되었습니다.');
        }
      },
      error => {
        console.error(error);
      });
    }
  }

  getListForP(){
    let data: any = this.srch;
    this.restApi.getReviews(data, res=>{
      if(res.header.status === CONSTANT.HttpStatus.OK){
        this.listCnt = res.body.docCnt;
        if(this.listCnt > 0){
          this.lists = res.body.docs;
          this.lists.forEach(each =>{
            each.KS = Math.round((each.KS) * 10) / 10;
            each.PS = Math.round((each.PS) * 10) / 10;
            each.CS = Math.round((each.CS) * 10) / 10;
            each.uploadFileList.forEach(file =>{
              if(file.GrpId == 1){
                each.link = this.StaticVariable.getFileDownloadUrl(file.PhysicalFileNm)
              }
            });
          });
        }
      }
    },
    error => {
      console.error(error);
    });
  }

  roundUp(num1, num2, num3){
    return Math.round(((num1 + num2 + num3) / 3) * 10) / 10;
  }

  getListForE(){

    let data = {};

    this.restApi.reviewsForEnter(data, res =>{
      if(res.header.status === CONSTANT.HttpStatus.OK){
        this.listCnt = res.body.docCnt;
        if(this.listCnt > 0){
          this.lists = res.body.docs;
        }
      }
    },
    error => {
      console.error(error);
    });
  }

  getSearch(){
    // 번호만 입력시 신청번호 검색
    if(/^[0-9]+$/.test(this.srch.keyword)){
      this.srch.attr = 'AppNo';      
    }else{
      this.srch.attr = 'Addr1';
    }
    this.currentPage = 1;
    this.target == 'personal' ? this.getListForP() : this.getListForE();

  }

}
