import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { Platform } from '@ionic/angular';
import { rootScope } from 'src/app/global/global';
import { FileUploadService } from 'src/app/service/file-upload.service';
import { SessionService } from 'src/app/service/session.service';
import { StaticVariableService } from 'src/app/service/static-variable.service';
import { WebView } from "@ionic-native/ionic-webview/ngx";
@Component({
  selector: 'app-file-attach-hybrid',
  templateUrl: './file-attach-hybrid.component.html',
  styleUrls: ['./file-attach-hybrid.component.scss'],
})
export class FileAttachHybridComponent implements OnInit, OnChanges{

  public library = [];
  public photos = [];
  public total : number = 0;
  public rootScope = rootScope;
  public uploading : boolean = false;

  @ViewChild('fileInput') fileInput : ElementRef;
  @Input('savedFile') savedFile : Array<any>;
  @Output() files = new EventEmitter();
  
  constructor(private platform : Platform, public gallery : ImagePicker, public fileUp : FileUploadService, public StaticVariable : StaticVariableService, private camera : Camera, private session: SessionService, private photoLibrary: PhotoLibrary,
              private webview : WebView) { }

  ngOnInit() {
    if(rootScope.isMobile){
      this.gallery.hasReadPermission().then(res => {
        if(res === false){
          this.gallery.requestReadPermission();
        }
      });
    }
  }
  
  ngOnChanges(){
    if(this.savedFile){
      this.convertLink();
      console.log(this.savedFile)
    }
  }

  getPhotho(){
    if(rootScope.isMobile){
      
      this.gallery.getPictures({
        maximumImagesCount: 10,
        quality : 50,
        width: 1000,
        height: 750,
        outputType : 1
      }).then(res=>{
        this.uploading = true;
        console.log(res);
        let attach = [];
        res.forEach(item => {
          attach.push('data:image/jpeg;base64,' + item);
        });

        // 사진은 최대 10장까지만
        this.total = attach.length + this.photos.length;
        if( this.total > 10){
          return;
        }
        this.fileUp.uploadFiles(attach).then(files => {
          files.forEach(item => {
            this.photos.push(item);
          });
          this.uploading = false;
          this.files.emit(this.photos);
        });
      });
    }else{
      this.fileInput.nativeElement.click();
    }
  }

  // web에서 사진 추가시 
  upload(e){
    const files = e.target.files;

    // 사진은 최대 10장까지만
    this.total = files.length + this.photos.length;
    if( this.total > 10){
      return;
    }else{
      this.fileUp.uploadFiles(files).then(res => {
        res.forEach(item => {
          this.photos.push(item);
        });
        
        this.files.emit(this.photos);
      });
    }
  }

  deletePhoto(idx : number){
    let item : any = {};
    
    item = this.photos[idx];
    
    this.fileUp.onFileDelete(item, res =>{
      this.photos.splice(idx, 1);
      this.files.emit(this.photos);
    },
    error =>{
      console.error(error);
    });
  }

  convertLink(){
    this.savedFile.forEach(item => {
      if(item.fileId){
        let obj = {
          id : item.fileId,
          isLoaded : true,
          link : this.StaticVariable.getFileDownloadUrl(item.PhysicalFileNm),
          fileName : item.LogicalFileNm,
          size : item.FileSize,
          fileKey : item.PhysicalFileNm,
          path : item.PhysicalPath,
          downloadCnt : item.DownloadCnt,
          grpId : item.GrpId
        }
        this.photos.push(obj);
      }
    });
    this.files.emit(this.photos);
  }

  pickImage(){
    const options: CameraOptions = {
      quality: 50,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      saveToPhotoAlbum : true,
      destinationType : 0
    }
    
    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      let file = [];
      file.push('data:image/jpeg;base64,' + imageData);
      this.fileUp.uploadFiles(file).then(res => {
        console.log(res);
        this.photos.push(res[0]);
        this.camera.cleanup();
      });
    }, (err) => {
      // Handle error
    });
  }
}
