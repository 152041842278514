import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceInterpreter'
})
export class PriceInterpreterPipe implements PipeTransform {

  transform(value: number, args?: string | number): any {
    // input-range to 가격
    if(args == 'price'){
      switch(value){
        case 1 : return 50;
        case 2 : return 100;
        case 3 : return 200;
        case 4 : return 300;
        case 5 : return 500;
        case 6 : return '1,000';
        case 7 : return '2,000';
        case 8 : return '3,000';
        case 9 : return '4,000';
        case 10 : return '5,000';
        case 11 : return '6,000';
        case 12 : return '7,000';
        case 13 : return '8,000';
        case 14 : return '9,000';
        case 15 : return '1억';
        case 16 : return '1억2,000';
        case 17 : return '1억5,000';
        case 18 : return '1억7,000';
        case 19 : return '2억';
        case 20 : return '2억5,000';
        case 21 : return '3억';
        case 22 : return '3억5,000';
        case 23 : return '4억';
        case 24 : return '5억';
        case 25 : return '7억';
        case 26 : return '10억';
        case 27 : return '12억';
        case 28 : return '15억';
        case 29 : return '20억';
        case 30 : return '25억';
        case 31 : return '30억';
        case 32 : return '35억';
        case 33 : return '40억';
        case 34 : return '50억';
        case 35 : return '60억';
        case 36 : return '70억';
        case 37 : return '80억';
        case 38 : return '90억';
        case 39 : return '100억';
        case 40 : return '150억';
        case 41 : return '200억';
        case 42 : return '300억';
        case 43 : return '400억';
        case 44 : return '500억';
        case 45 : return '1000억';
      }
    // input-range to 월세가격
    }else if(args == 'rent'){
      switch(value){
        case 1 : return 5;
        case 2 : return 10;
        case 3 : return 15;
        case 4 : return 20;
        case 5 : return 25;
        case 6 : return 30;
        case 7 : return 35;
        case 8 : return 40;
        case 9 : return 50;
        case 10 : return 60;
        case 11 : return 70;
        case 12 : return 100;
        case 13 : return 150;
        case 14 : return 200;
        case 15 : return 250;
        case 16 : return 300;
        case 17 : return 400;
        case 18 : return 500;
        case 19 : return 800;
        case 20 : return 1000;
        case 21 : return 1200;
        case 22 : return 1500;
        case 23 : return 1800;
        case 24 : return 2000;
      }
    // input-range to 전용면적
    }else if(args == 'area'){
      switch(value){
        case 1 : return 66;
        case 2 : return 99;
        case 3 : return 165;
        case 4 : return 231;
        case 5 : return 264;
        case 6 : return 297;
        case 7 : return 330;
        case 8 : return 396;
        case 9 : return 495;
        case 10 : return 660;
        case 11 : return 825;
        case 12 : return 991;
        case 13 : return 1156;
        case 14 : return 1321;
        case 15 : return 1486;
        case 16 : return 1652;
      }
    // input-range to 가격(DB인서트)
    }else if(args == 'utop'){
      switch(value){
        case 1 : return 50;
        case 2 : return 100;
        case 3 : return 200;
        case 4 : return 300;
        case 5 : return 500;
        case 6 : return 1000;
        case 7 : return 2000;
        case 8 : return 3000;
        case 9 : return 4000;
        case 10 : return 5000;
        case 11 : return 6000;
        case 12 : return 7000;
        case 13 : return 8000;
        case 14 : return 9000;
        case 15 : return 10000;
        case 16 : return 12000;
        case 17 : return 15000;
        case 18 : return 17000;
        case 19 : return 20000;
        case 20 : return 25000;
        case 21 : return 30000;
        case 22 : return 35000;
        case 23 : return 40000;
        case 24 : return 50000;
        case 25 : return 70000;
        case 26 : return 100000;
        case 27 : return 120000;
        case 28 : return 150000;
        case 29 : return 200000;
      }
    // 가격 to input-range
    }else if(args == 'ptou'){
      switch(value){
        case 50 : return 1;
        case 100 : return 2;
        case 200 : return 3;
        case 300 : return 4;
        case 500 : return 5;
        case 1000 : return 6;
        case 2000 : return 7;
        case 3000 : return 8;
        case 4000 : return 9;
        case 5000 : return 10;
        case 6000 : return 11;
        case 7000 : return 12;
        case 8000 : return 13;
        case 9000 : return 14;
        case 10000 : return 15;
        case 12000 : return 16;
        case 15000 : return 17;
        case 17000 : return 18;
        case 20000 : return 19;
        case 25000 : return 20;
        case 30000 : return 21;
        case 35000 : return 22;
        case 40000 : return 23;
        case 50000 : return 24;
        case 70000 : return 25;
        case 100000 : return 26;
        case 120000 : return 27;
        case 150000 : return 28;
        case 200000 : return 29;
      }
    // 월세가격 to input-range
    }else if(args == 'rtou'){
      switch(value){
        case 5 : return 1;
        case 10 : return 2;
        case 15 : return 3;
        case 20 : return 4;
        case 25 : return 5;
        case 30 : return 6;
        case 35 : return 7;
        case 40 : return 8;
        case 50 : return 9;
        case 60 : return 10;
        case 70 : return 11;
        case 100 : return 12;
        case 150 : return 13;
        case 200 : return 14;
        case 250 : return 15;
        case 300 : return 16;
        case 400 : return 17;
        case 500 : return 18;
        case 800 : return 19;
      }
    // 전용면적 to input-range
    }else if(args == 'atou'){
      switch(value){
        case 66 : return 1;
        case 99 : return 2;
        case 165 : return 3;
        case 231 : return 4;
        case 264 : return 5;
        case 297 : return 6;
        case 330 : return 7;
        case 396 : return 8;
        case 495 : return 9;
        case 660 : return 10;
        case 825 : return 11;
        case 991 : return 12;
        case 1156 : return 13;
        case 1321: return 14;
        case 1486 : return 15;
        case 1652: return 16;
      }
    }else if(args == 'ktom'){
      if(value > 9999){
        const val = value.toString();
        const m = val.substring(0, val.length - 4);
        let k : number = parseInt(val.substring(val.length - 4));
        return m + '억' + (k > 0 ? k : '');
      }else{
        return value;
      }
    }
  }

}
