import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { TermsComponent } from 'src/app/component/terms/terms.component';
import { RestApiService } from 'src/app/service/rest-api.service';
import { SessionService } from 'src/app/service/session.service';
import { ToastService } from 'src/app/service/toast-service';
import { CONSTANT } from 'src/assets/global-constant';

@Component({
  selector: 'app-password-auth',
  templateUrl: './password-auth.component.html',
  styleUrls: ['./password-auth.component.scss'],
})
export class PasswordAuthComponent implements OnInit {
  id: string;
  pw: string;
  tokenId: any;
  public lists: any;
  public listCnt: number;

  constructor(public modalCtrl: ModalController, public session: SessionService, public router: Router, private restApi: RestApiService, public toast: ToastService) {
    this.session.action.subscribe(res => {
      console.log(res);
    });
  }
  ngOnInit() {
    this.getData()
  }

  getData(){
    let data: any = {};
    this.restApi.selectProfile(data, res => {
      if (res.header.status === CONSTANT.HttpStatus.OK) {
        if (res.body.docCnt > 0) {
          this.lists = res.body.docs;
          this.listCnt = this.lists.length;
          this.id = this.lists[0].LoginId
          console.log('lists', this.lists);
          console.log(this.lists[0].LoginId)
        }
        else {
          this.lists = [];
          this.listCnt = 0;
          console.log(data)
        }
      }
    },
      error => {
        console.error(error);
      });
  }

  password() {
    let loginData: any = { id: this.id, pw: this.pw, token: this.session.getToken() };
    if (this.tokenId) {
      loginData.TokenId = this.tokenId;
    }

    this.restApi.signIn(loginData, (res: any) => {
      if (res.header.status === CONSTANT.HttpStatus.OK) {
        let result = res.body.docs[0];
        this.session.sessionIn(result);        
        this.modalCtrl.dismiss(loginData);        
        console.log('result',result);
        console.log('loginData',loginData);
      } else {
        this.toast.presentToast('패스워드 오류입니다.');
      }
    },
      error => {
        console.error(error);
      });
  }
}


