import { Component, OnInit } from '@angular/core';
import { RestApiService } from 'src/app/service/rest-api.service';
import { ToastService } from 'src/app/service/toast-service';
import { Router,ActivatedRoute } from '@angular/router';
import { CONSTANT } from 'src/assets/global-constant';
import { NavigationService } from 'src/app/service/navigation.service';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { booleanToYN, formCheck, ValidatorsExt } from 'src/app/global/global';
import { AlertController } from '@ionic/angular';
import { SessionService } from 'src/app/service/session.service';

@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.scss'],
})


export class InquiryComponent implements OnInit {
  public data: any = {};
  QuestType: string;
  CustNm: string;
  Email: string;
  QuestCont: string;
  public questForm: FormGroup;
  public submitForm: FormGroup;
  public valid: any;
  public custType: string;
  public param : any;

  constructor(private toastCtrl: ToastService, public actRoute: ActivatedRoute, public fb: FormBuilder, public restApi: RestApiService, public router: Router, private navigation: NavigationService,
              private alertCtrl : AlertController, private session : SessionService) {
    this.questForm = fb.group({
      QuestType: ['', []],
      CustNm: ['', [
        Validators.required
      ]],
      Email: ['', [
        Validators.required,
        Validators.pattern('^[_a-zA-Z0-9-\\.]+@[\\.a-zA-Z0-9-]+\\.[a-zA-Z]+$')
      ]],
      QuestCont: ['', []]
    });
    this.submitForm = fb.group({
      EmailYN: [false, []],
      PublicYN: [false, []]
    });

    this.valid = { ...this.questForm.controls };
    this.custType = actRoute.snapshot.params.custType;
  }

  ngOnInit() {}


  submit() {
    if(!this.session.user.value.CustNm){
      this.authrize();
      return;
    }else if (this.validCheck()) {
      return;
    }

    let data: any = { ...this.questForm.value, ...this.submitForm.value };
    data.QuestType = this.questForm.controls.QuestType.value;
    data = booleanToYN(data);
    this.restApi.insertQuestion(data, res => {
      if (res.header.status === CONSTANT.HttpStatus.OK) {
        console.log(this.custType)
        this.toastCtrl.presentToast('전송 되었습니다.')
        if(this.custType == 'P'){
        this.router.navigateByUrl('/personal/main');
        }else{
          this.router.navigateByUrl('/enterprise/main');

        }
      } else {
        this.toastCtrl.presentToast('전송되지 않았습니다.')
      }
    },
      error => {
        console.log(error);
      })
    console.log(data)
  }

  validCheck(): boolean {


    if (!this.questForm.controls.QuestType.value) {
      this.toastCtrl.presentToast('분야를 선택해주세요.');
      return true;
    }
    if (formCheck(this.questForm.controls)) {
      this.toastCtrl.presentToast('입력란을 확인해주세요.')
      return true;
    }

  }

  back(): void {
    this.navigation.back();
  }

  async authrize() {
    const alert = await this.alertCtrl.create({
      message: '개인인증이 필요합니다. 이동하시겠습니까?',
      buttons: [
        {
          text: '취소',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            this.navigation.back();            
          }
        }, {
          text: '이동',
          handler: () => {
            this.router.navigateByUrl('/personal/profile/P', {skipLocationChange : true});
          }
        }
      ]
    });

    await alert.present();
  }

}
