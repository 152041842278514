import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { GooglePlus } from "@ionic-native/google-plus/ngx";
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { Platform } from '@ionic/angular';
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { KakaoCordovaSDK } from 'kakao-sdk/ngx';
import { NaverCordovaSDK } from 'naver-sdk/ngx';
import { RestApiService } from './rest-api.service';
import firebase from 'firebase/app';
import { rejects } from 'assert';
import { rootScope } from '../global/global';

@Injectable()
export class SnsLoginService implements OnInit{

  private user : any;
  public naverLogin;
  constructor(public fb : Facebook, public platform : Platform, public auth : AngularFireAuth, private naver : NaverCordovaSDK, private kakao : KakaoCordovaSDK,
              private authService : SocialAuthService, public restApi : RestApiService, public google : GooglePlus) { 
    authService.authState.subscribe(data => {
      console.log(data);
    });
  }

  ngOnInit(){
  }

  withGoogle(){
    if(rootScope.isMobile){
      return this.google.login({}).then(res =>{
        let data = {
          accessToken : res.accessToken,
          expiresAt : res.expires,
          id : res.userId
        }
        return data;
      }).catch(error =>{
        return error;
      })
    }else{
      return this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(res =>{
        let data : any =  res.additionalUserInfo.profile;
        let tokenManage : any = res.user.toJSON();
        data.accessToken = tokenManage.stsTokenManager.accessToken;
        console.log(data);
        return data;
      });
      // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(data => console.log('data', data));
    }
  }

  withFacebook(){
    if(rootScope.isMobile){
      return this.fb.getLoginStatus().then(connection => {
        let data : any;
        if(connection.status == 'connected'){
          data = {
            accessToken : connection.authResponse.accessToken,
            expiresAt : connection.authResponse.expiresIn,
            id : connection.authResponse.userID  
          }
          return data;
        }else{
          return this.fb.login(['public_profile', 'user_friends', 'email'])
          .then((res: FacebookLoginResponse) => {
            data = {
              accessToken : res.authResponse.accessToken,
              expiresAt : res.authResponse.expiresIn,
              id : res.authResponse.userID
            }
            return data;
          })
          .catch(e => {
            console.log('Error logging into Facebook', e)
            return e;
          });
        }
      });
      
      this.fb.logEvent(this.fb.EVENTS.EVENT_NAME_ADDED_TO_CART);
    }else{
      return this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(data => {
        console.log('facebook', data)
        return data;
      });
    }
  }

  withKakao(){
    if(rootScope.isMobile){
      return this.kakao.login().then(res => {
        return res;
      }).catch(error => {
        return error;
      });
    }else{
      return new Promise((resolve, rejects) => {
        Kakao.Auth.login({
          success : (res) => {
            let data : any = {
              access_token : res.access_token,
              expires : res.expires_in
            };
            Kakao.API.request({
              url: '/v2/user/me',
              success: function(response) {
                  data.id = response.id;
                  resolve(data);
              },
              fail: function(error) {
                  rejects(error);
                  console.log(error);
              }
            });
          },
          fail : (error) =>{
            rejects(error);
          }
        });
      });
    }
  }

  withNaver(keyId? : string){
    if(rootScope.isMobile){
      return this.naver.login().then(res => {
        return res;
      });
    }else{
      const callbackRoute =  keyId ? '/web/login/' + keyId : '/home/login';
      sessionStorage.setItem('callbackUrl', callbackRoute);

      let login = new naver.LoginWithNaverId({
        clientId: "2BbN1nTEILuBPW9ec4KW",
        callbackUrl: "https://anybang.co.kr/home/callback",
        isPopup: false
      });
      login.init();
      login.authorize();
    }
  }

  async callback(callback : Window){
    naver.getResponse = async function(){
      console.log(naver.response);
      if(naver.response){
        return naver.response;
      }
    } 

    callback.onbeforeunload = () =>{
      console.log('!@#!@3');
      return naver.getResponse();
    };
    // return await naver.getResponse();
  }

  
}


// t.prototype.popup=function(){
//   var t=this.generateAuthorizeUrl();
//   new c["default"](t,"300","400",null,"naver_login",null).attachOnclickEvent("naverIdLogin_loginButton")},
// t.prototype.popup=function(){
//   this.makeDefaultOptions(),
//   window.open(this.url,this.target,this.options)}
//   ,