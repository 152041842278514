import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { SrchAddrComponent } from 'src/app/component/srch-addr/srch-addr.component';
import { PasswordAuthComponent } from 'src/app/component/password-auth/password-auth.component';
import { ToastService } from 'src/app/service/toast-service';
import { booleanToYN, formCheck, ValidatorsExt } from 'src/app/global/global';
import { CONSTANT } from 'src/assets/global-constant';
import { RestApiService } from 'src/app/service/rest-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IamportCordova } from '@ionic-native/iamport-cordova';
import { SessionService } from 'src/app/service/session.service';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default

})
export class ProfileComponent implements OnInit {

  public data: any = {};
  public loginForm: FormGroup;
  public infoForm: FormGroup;
  public entForm: FormGroup;
  public submitForm: FormGroup;
  public valid: any;
  public submitted: boolean = false;
  public duplicate: boolean;
  public duplicateMobile: boolean;
  public checkedId: string;
  public custId: string;
  public custType: string;
  public snsLogin : string;
  public pickSpec: boolean = false;
  public verifiedPersonalInfo: boolean = false;
  public drop;
  public val;
  public photo;
  public license;
  public biz;
  public agreed : boolean = false;
  public oriCustNm: string;
  public oriMobile: string;
  currentBytes = 0;
  textMaxLength = 300;

  constructor(private modalCtrl: ModalController, public fb: FormBuilder, private toastCtrl: ToastService, public actRoute: ActivatedRoute, public restApi: RestApiService, public router: Router, private session : SessionService
    , private clipboard : Clipboard, public toast: ToastService) {

    this.loginForm = fb.group({
      Password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern("^(?=.*[a-zA-Z0-9])(?=.*[a-zA-Z!`~@#$%^&*()_=+{}|?<>,./\-])(?=.*[a-z0-9!`~@#$%^&*()_=+{}|?<>,./\-])(?=.*[A-Z0-9!`~@#$%^&*()_=+{}|?<>,./\-]).{8,}$") // 대문자, 소문자, 숫자, 특수문자 중 2개조합 8자리이상
      ]],
      rePassword: ['', [
        Validators.required,
        Validators.minLength(8),
        ValidatorsExt.match
      ]],
    });

    this.infoForm = fb.group({
      CustNm: ['', [
        // Validators.required
      ]],
      Email: ['', [
        // Validators.required,
        // Validators.pattern('^[_a-zA-Z0-9-\\.]+@[\\.a-zA-Z0-9-]+\\.[a-zA-Z]+$')
      ]],
      ZipCode: ['', []],
      Addr1: ['', []],
      Addr2: ['', []],
      JoinPath: ['DOMESTIC', [
        Validators.required
      ]],
      RecomCustId: ['', []],
      Mobile: ['', [
        // Validators.required,
        // Validators.pattern('^[0-9-]{10,11}$')
      ]],
    });

    this.entForm = fb.group({
      TenantNm: ['', [
        // Validators.required
      ]],
      Ceo: ['', [
        // Validators.required
      ]],
      TelNo: ['', []],
      TradeNo: ['', []],
      BizRegNo: ['', []],
      TenantZip: ['', [
        // Validators.required
      ]],
      TenantAddr1: ['', [
        // Validators.required
      ]],
      TenantAddr2: ['', []],
      Sigungucode: ['', []],
      Position: ['', []],
      ContactTelNo: ['', [
        // Validators.required,
        // Validators.pattern('^[0-9-]+$')
      ]],
      SpecYN1: [false, []],
      SpecYN2: [false, []],
      SpecYN3: [false, []],
      SpecYN4: [false, []],
      SpecYN5: [false, []],
      SpecYN6: [false, []],
      Comments: ['', []]
    });

    this.submitForm = fb.group({
      EmailYN: [false, []],
      SmsYN: [false, []],
      KakaoYN: [false, []],
      PublicYN: [false, []],
    });

  }
  
  ngOnInit() {
    this.getdata();
    this.valid = { ...this.loginForm.controls, ...this.infoForm.controls, ...this.entForm.controls };

    // sns 로그인 회원은 패스워드 변경하지 않음
    this.snsLogin = this.session.user.value.LoginType;
    this.custType = this.session.user.value.CustType;
  }

  getdata() {
    let data: any = {
      IdName : 'CustId',
      tableNm : 'anycustomer',
      uploadFileList : []
    };

    this.restApi.selectProfile(data, res => {
      if (res.header.status === CONSTANT.HttpStatus.OK) {
        if (res.body.docCnt > 0) {
          this.data = res.body.docs[0];

          this.oriCustNm = res.body.docs[0].CustNm;
          this.oriMobile = res.body.docs[0].Mobile;
          this.viewData(this.data);
        }
        else {
          this.data = {};
          console.log(data)
        }
      }
    },
      error => {
        console.error(error);
      });
  }

  srchAddr(dat: string) {
    this.presentModal(dat);
  }

  async presentModal(dat: string) {
    if (dat == 'tenant') {
      const modal = await this.modalCtrl.create({
        component: SrchAddrComponent,
      });
      let res = modal.onWillDismiss();
      res.then(data => {
        if (data.data) {
          this.entForm.controls.TenantZip.setValue(data.data.zonecode);
          this.entForm.controls.TenantAddr1.setValue(data.data.address);
          this.entForm.controls.Sigungucode.setValue(data.data.sigunguCode);
        }
      });
      return await modal.present();
    }
    else {
      const modal = await this.modalCtrl.create({
        component: SrchAddrComponent,
      });
      console.log(dat)
      let res = modal.onWillDismiss();
      res.then(data => {
        if (data.data) {
          this.infoForm.controls.ZipCode.setValue(data.data.zonecode);
          this.infoForm.controls.Addr1.setValue(data.data.address);
        }
      });
      return await modal.present();
    }
  }

  authMob() {
    if (this.verifiedPersonalInfo) {
      this.verifiedPersonalInfo = false;
      return;
    }

    if (this.infoForm.controls.CustNm.value == '') {
      this.toastCtrl.presentToast('이름을 입력하세요.');
      return true;
    }

    if (this.infoForm.controls.Mobile.value == '') {
      this.toastCtrl.presentToast('휴대폰번호를 입력하세요.');
      return true;
    }

    const mob = this.infoForm.controls.Mobile;
    const result = mob.value.replaceAll('-', '');
    const regExp = /^[0-9-]{10,11}$/;
    mob.setValue(result);

    // 휴대폰번호 중복체크 로직
    let mobileData: any = {};
    mobileData.CustNm = this.infoForm.controls.CustNm.value;
    mobileData.Mobile = result;

    // 이름 또는 핸드폰번호가 수정되지 않았을 경우 
    if (this.oriCustNm == this.infoForm.controls.CustNm.value && this.oriMobile == this.infoForm.controls.Mobile.value) {
      if (regExp.test(result)) {
        var userCode = 'imp86263784';                       // 가맹점 식별코드
        var data = {
          carrier: '',                                      // 통신사
          merchant_uid: 'mid_' + new Date().getTime(),      // 주문번호
          company: 'SIOT',                                  // 회사명
          name: this.infoForm.controls.CustNm.value,        // 이름
          phone: this.infoForm.controls.Mobile.value,       // 전화번호
        };
        var titleOptions = {
          text: '아임포트 코르도바 테스트',                   // 타이틀
          textColor: '#ffffff',                             // 타이틀 색
          textSize: '20',                                   // 타이틀 크기
          textAlignment: 'left',                            // 타이틀 정렬 유형
          backgroundColor: '#344e81',                       // 타이틀 배경색
          show: true,                                       // 타이틀 유무
          leftButtonType: 'back',                           // 왼쪽 버튼 유형
          leftButtonColor: '#ffffff',                       // 왼쪽 버튼 색
          rightButtonType: 'close',                         // 오른쪽 버튼 유형
          rightButtonColor: '#ffffff',                      // 오른쪽 버튼 색
        };
  
        // 4. 아임포트 코르도바 파라미터 정의
        var params = {
          userCode: userCode,                               // 4-1. 가맹점 식별코드 정의
          data: data,                                       // 4-2. 결제 데이터 정의
          titleOptions: titleOptions,                       // 4-3. 본인인증 창 헤더 옵션 정의
          callback: response => {                           // 4-3. 콜백 함수 정의
            if (response.imp_success = 'true') {
              console.log(response);
              this.verifyPersonalInfo(true);
            } else {
              this.verifyPersonalInfo(false);
            }
          },
        };
  
        // 5. 본인인증 창 호출
        IamportCordova.certification(params);
        return;
      }
    }
    // 이름 또는 핸드폰번호가 수정되었을 경우 중복체크
    else {
      this.restApi.duplicateMobileCheck(mobileData, (res: any) => {
        if (res.header.status === CONSTANT.HttpStatus.OK) {
          // 휴대폰번호 중복 처리
          if (res.body.docs[0].result > 0) {
            this.duplicateMobile = true;

            this.toastCtrl.presentToast('이미 등록된 휴대폰번호 입니다.');
            return;
          } 
          // 휴대폰번호가 중복이 아니고 정규식이 맞다면 인증 처리
          else {
            this.duplicateMobile = false;
            
            if (regExp.test(result)) {
              var userCode = 'imp86263784';                       // 가맹점 식별코드
              var data = {
                carrier: '',                                      // 통신사
                merchant_uid: 'mid_' + new Date().getTime(),      // 주문번호
                company: 'SIOT',                                  // 회사명
                name: this.infoForm.controls.CustNm.value,        // 이름
                phone: this.infoForm.controls.Mobile.value,       // 전화번호
              };
              var titleOptions = {
                text: '아임포트 코르도바 테스트',                   // 타이틀
                textColor: '#ffffff',                             // 타이틀 색
                textSize: '20',                                   // 타이틀 크기
                textAlignment: 'left',                            // 타이틀 정렬 유형
                backgroundColor: '#344e81',                       // 타이틀 배경색
                show: true,                                       // 타이틀 유무
                leftButtonType: 'back',                           // 왼쪽 버튼 유형
                leftButtonColor: '#ffffff',                       // 왼쪽 버튼 색
                rightButtonType: 'close',                         // 오른쪽 버튼 유형
                rightButtonColor: '#ffffff',                      // 오른쪽 버튼 색
              };
        
              // 4. 아임포트 코르도바 파라미터 정의
              var params = {
                userCode: userCode,                               // 4-1. 가맹점 식별코드 정의
                data: data,                                       // 4-2. 결제 데이터 정의
                titleOptions: titleOptions,                       // 4-3. 본인인증 창 헤더 옵션 정의
                callback: response => {                           // 4-3. 콜백 함수 정의
                  if (response.imp_success = 'true') {
                    console.log(response);
                    this.verifyPersonalInfo(true);
                  } else {
                    this.verifyPersonalInfo(false);
                  }
                },
              };
        
              // 5. 본인인증 창 호출
              IamportCordova.certification(params);
              return;
            }
          }
        }
      },
      error => {
        console.error(error);
      });
    }
  }

  verifyPersonalInfo = function (param) {
    if (param) {
      this.toastCtrl.presentToast('인증 되었습니다.');
      document.getElementById("CustNm").setAttribute("disabled", "");
      document.getElementById("Mobile").setAttribute("disabled", "");
      document.getElementById("strVerify").style.display = "none";
      document.getElementById("strReVerify").style.display = "block";
    } else {
      this.toastCtrl.presentToast('인증되지 않았습니다.');
    }

    this.verifiedPersonalInfo = param;
  }

  validCheck() {
    // 개인정보 Form Valid Check
    if (formCheck(this.infoForm.controls)) {
      this.toastCtrl.presentToast('입력란을 확인해주세요.')
      return true;
    }

    // 휴대폰번호 인증 체크
    // if (this.oriCustNm != this.infoForm.controls.CustNm.value || this.oriMobile != this.infoForm.controls.Mobile.value) {
    //   if (this.duplicateMobile || this.duplicateMobile == undefined || !this.verifiedPersonalInfo) {
    //     this.toastCtrl.presentToast('휴대폰번호를 인증요청 해주세요.')
    //     return true;
    //   }
    // }

    // 중개사정보 Form Valid Check
    if (this.custType == 'E') {
      if (formCheck(this.entForm.controls)) {
        this.toastCtrl.presentToast('입력란을 확인해주세요.')
        return true;
      }

      const specs = this.entForm.value;
      let specCnt = 0;
      for (const key in specs) {
        if (/YN/.test(key) && specs[key]) {
          specCnt++;
          break;
        }
      }

      if (specCnt == 0) {
        this.pickSpec = true;
        this.toastCtrl.presentToast('전문분야를 선택해주세요.');
        return true;
      }else if(!this.photo){
        this.toastCtrl.presentToast('사진을 선택해주세요.');
        return true;
      }
      // else if(!this.license){
      //   this.toastCtrl.presentToast('중개사 등록증을 선택해주세요.');
      //   return true;
      // }else if(!this.biz){
      //   this.toastCtrl.presentToast('사업자 등록증을 선택해주세요.');
      //   return true;
      // }
    }

    return false;
  }

  validCheckPW() {
    if (/* this.loginForm.value.Password != '' && */ this.drop == 1 ) {
      if (formCheck(this.loginForm.controls)) {
        this.toastCtrl.presentToast('입력란을 확인해주세요.')
        return true;
      }
      else {
        if(this.val == undefined){
        this.toastCtrl.presentToast('확인되었습니다.')
        }
      }
    } else {
      this.toastCtrl.presentToast('입력란을 확인해주세요..')
      return true;
    }
  }

  // update
  modify() {                 
    // if (this.validCheck()) {
    //   return;
    // }
    if (/* this.loginForm.value.Password != '' && */ this.drop == 1) {
      this.val = 1;
      if (this.validCheckPW()) {
        return;
      }
    }

    let data : any = this.prepData();

    this.restApi.updateProfile(data, (res: any) => {
      if (res.header.status === CONSTANT.HttpStatus.OK) {
        if (res.body.docCnt > 0) {
          const info = res.body.docs[0];
          if (info.result == 10) {
            this.toastCtrl.presentToast('등록된 사업자입니다.');
            return;
          } else if (info.result == 0) {
            this.toastCtrl.presentToast('잘못된 접근입니다.');
            return;
          } else {
            if (this.loginForm.value.Password != '' && this.drop == 1) {
              this.restApi.updatePassword(data, (res: any) => {
                if (res.header.status === CONSTANT.HttpStatus.OK) {
                  if (res.body.docCnt > 0) {
                    const info = res.body.docs[0];
                    if (info.result == 10) {
                      this.toastCtrl.presentToast('등록된 사업자입니다.');
                      return;
                    } else if (info.result == 0) {
                      this.toastCtrl.presentToast('잘못된 접근입니다.');
                      return;
                    } else {
                      this.toastCtrl.presentToast('수정 되었습니다.');
                      const url = info.CustType == 'P' ? '/personal' : '/enterprise';
                      this.router.navigate([url], {replaceUrl: true});
                      this.router.ngOnDestroy();                    
                    }
                  }
                }
              },
                (error: any) => {
                });
            }
            else {
              this.toastCtrl.presentToast('수정 되었습니다.');
              let newSession = this.setUserInfo(data);
              this.session.sessionIn(newSession);
              
              const url = info.CustType == 'P' ? '/personal' : '/enterprise';
              this.router.navigate([url], {replaceUrl: true});
              this.router.ngOnDestroy();
            }
          }
        }
      }
    },
    (error: any) => {
    });
  }

  getPropData() {
    // data.prop = 1;
    this.passwordModal();
  }

  async passwordModal() {
    const modal = await this.modalCtrl.create({
      component: PasswordAuthComponent,
    });
    let res = modal.onWillDismiss();
    res.then(data => {
      if (data.data) {
        this.drop = 1;
      }
    });
    return await modal.present();
  }

  YNToboolean(yn: string) {
    return (yn == 'Y') ? true : false;
  }

  // DB Data - componentView Rendering
  viewData(data : any){
    for(const key in this.infoForm.controls){
      if(data[key]){
        this.infoForm.controls[key].setValue(data[key]);
      }
    }
    for(const key in this.entForm.controls){
      // YN 데이터 -> true, false 변환
      if(key.indexOf('YN') > -1){
        let bool = data[key] == 'Y' ? true : false;
        this.entForm.controls[key].setValue(bool);  
      }else{
        const temp = data[key] ? data[key] : '';
        this.entForm.controls[key].setValue(temp);
        if(key === 'Comments') {
          this.calcTextCnt(data[key]);
        }
      }
    }
    for(const key in this.submitForm.controls){
      let bool = data[key] == 'Y' ? true : false;
      this.submitForm.controls[key].setValue(bool);  
    }
    
    data.uploadFileList.forEach(each =>{
      switch(each.GrpId){
        case 1 : this.photo = each;
        return;
        case 2 : this.license = each;
        return;
        case 3 : this.biz = each;
        return;
      }
    })
  }

  agree(){
    for(const key in this.submitForm.controls){
        this.submitForm.controls[key].setValue(!this.agreed);
      }
    this.agreed = !this.agreed;
  }

  prepData(){
    let data = { ...this.infoForm.value, ...this.submitForm.value };

    if (this.custType == 'E') {
      data = { ...data, ...this.entForm.value };
      if (this.loginForm.value.Password != '') {
        data = { ...data, ...this.loginForm.value };
      }
      data.uploadFileList = [
        this.photo, this.license, this.biz
      ];
      data.IdName = 'CustId';
      data.tableNm = 'anycustomer';
    }else {
      if (this.loginForm.value.Password != '') {
        data = { ...data, ...this.loginForm.value };
      }
    }

    // 개인정보 및 레터수신 여부 병합
    data = booleanToYN(data);
    data.Specs = [data.SpecYN1, data.SpecYN2, data.SpecYN3, data.SpecYN4, data.SpecYN5, data.SpecYN6];
    data.CustType = this.custType;
    // data.mapCode = 'SignIn.signUp';

    return data;
  }
  
  setUserInfo(data : any){
    let userInfo = this.session.user.value;
    userInfo.CustNm = data.CustNm;
    userInfo.Mobile = data.Mobile;
    return userInfo;
  }

  copyUrl(to : string){
    let keyId = this.session.user.value.LoginId;
    this.toast.presentToast('클립보드에 저장되었습니다.');
    if(to == 'my'){
      this.clipboard.copy('https://anybang.co.kr/my/' + keyId);
    }else{
      this.clipboard.copy('https://anybang.co.kr/web/login/' + keyId);
    }
  }
  
  calcTextCnt(str: string){
    this.textMaxLength = 300;
    let bytes = 0;
    for(let i = 0; i < str.length; i++) {
      if(bytes > 300) {
        this.textMaxLength = i;
        this.entForm.controls['Comments'].setValue(str.substr(0, i - 1));
        break;
      }
      if(escape(str[i]).length === 6) {
        bytes++;
      }
      bytes++;
    }
    this.currentBytes = bytes;    
  }
  
}
