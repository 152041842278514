import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { RestApiService } from 'src/app/service/rest-api.service';
import { SessionService } from 'src/app/service/session.service';
import { ToastService } from 'src/app/service/toast-service';
import { CONSTANT } from 'src/assets/global-constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public custType : string;
  public name : string;
  public phone : string;
  public custId : string;
  public loginId: string;
  
  constructor(private session : SessionService, public router : Router, public menuCtl : MenuController, public restApi: RestApiService, public toast: ToastService) {
    
    session.user.subscribe(data => {
      this.custId = data.CustId;
      this.name = data.CustNm;
      this.phone = data.Mobile;
      this.custType = data.CustType;
      this.loginId = data.LoginId;
    });
  }

  ngOnInit() {
    if ((localStorage.getItem('CustId') == '' || localStorage.getItem('CustId') == undefined) && (sessionStorage.getItem('CustId') == '' || sessionStorage.getItem('CustId') == undefined)) {
      this.custType = 'N';
    } else {
      if(localStorage.getItem('CustId')){
        this.custType = localStorage.getItem('CustType');
      }
      else if(sessionStorage.getItem('CustId')){
        this.custType = sessionStorage.getItem('CustType');
      }
    }
  }

  logout() {
    this.restApi.signOut((res: any) => {
      if (res.header.status === CONSTANT.HttpStatus.OK) {
        if (res.body.docCnt > 0) {
          this.toast.presentToast('로그아웃 되었습니다.');
          this.session.sessionOut();
          this.router.navigateByUrl('home');
        }
      }
    });
  }

  appList(target: string) {
    if (this.custType == 'N') {
      this.router.navigateByUrl('home');
    } else if (this.custType == 'P') {
      this.router.navigateByUrl('personal/application-list/' + target);
    } else {
      this.router.navigateByUrl('enterprise/revauction/' + target);
    }
  }

  moveTo(url: string) {
    let moveTo: string;
    if (url == 'profile' || url == 'inquiry') {
      if (this.custType == 'N') {
        moveTo = 'home'
      } else if (this.custType == 'P') {
        moveTo = '/personal/' + url + '/' + this.custType;
      } else {
        moveTo = '/enterprise/' + url + '/' + this.custType;
      }
    } else {
      if (this.custType == 'N') {
        moveTo = 'home'
      } else if (this.custType == 'P') {
        moveTo = '/personal/' + url;
      } else {
        moveTo = '/enterprise/' + url;
      }
    }
    this.router.navigateByUrl(moveTo);
  }
}
