import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buildtype'
})
export class BuildtypePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    let result : string;
    switch(value){
      case 'INNOVATE' : result = '지산';
      break;
      case 'SHOP' : result = '상가/점포';
      break;
      case 'APARTMENT' : result = '아파트';
      break;
      case 'OFFICETEL' : result = '오피스텔';
      break;
      case 'STUDIO' : result = '원룸/투룸';
      break;
      case 'VILLA' : result = '빌라/단독주택';
      break;
    }
    return result;
  }

}
