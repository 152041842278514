export const fireConfig = {
    apiKey: "AIzaSyC3NDM-tPu71XVs0xz-xkjexCVpoQkTyKU",
    authDomain: "anybang-8e220.firebaseapp.com",
    databaseURL: "https://anybang-8e220-default-rtdb.firebaseio.com",
    projectId: "anybang-8e220",
    storageBucket: "anybang-8e220.appspot.com",
    messagingSenderId: "983519888281",
    appId: "1:983519888281:web:7e069b9b8da5509b040908",
    measurementId: "G-HRNYTQM06X"
};
