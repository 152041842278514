import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'comma'
})
export class CommaPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    if(!value){
      return '0';
    }
    let val = value.toString();
    const len = val.length;
    for(let i = 1; len > 3 * i; i++){
      val = val.substr(0, len - 3 * i) + ',' + val.substr(len - 3 * i);
    }

    return val;
  }

}
