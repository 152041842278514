import { Component, Input, OnInit } from '@angular/core';
import { ToastService } from 'src/app/service/toast-service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-find-id',
  templateUrl: './find-id.component.html',
  styleUrls: ['./find-id.component.scss'],
})
export class FindIdComponent implements OnInit {

  @Input() idfind: any;

  constructor( public router: Router, public toastCtrl: ToastService, public modalCtrl: ModalController) { 
  
  }

  ngOnInit() {
    this.getData()
    console.log('come',this.idfind)
  }

  getData() {
 
  }

  back() {
    this.modalCtrl.dismiss();
  }

  ok() {
    this.modalCtrl.dismiss();
  }
}
