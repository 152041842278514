import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RestApiService } from 'src/app/service/rest-api.service';
import { CONSTANT } from 'src/assets/global-constant';

@Component({
  selector: 'app-prop-list',
  templateUrl: './prop-list.component.html',
  styleUrls: ['./prop-list.component.scss'],
})
export class PropListComponent implements OnInit {

  @Input() buildType : string;

  public lists : any = [];
  public listCnt : number = 0;
  public currentPage = 1;
  constructor(public modalCtrl : ModalController, private restApi : RestApiService) { }

  ngOnInit() {
    this.getList();
  }

  getList(){
    let data = {
      BuildType : this.buildType,
      page : this.currentPage,
      viewSize : 5,
    };

    this.restApi.selectPropList(data, res=>{
      if(res.header.status === CONSTANT.HttpStatus.OK){
        if(res.body.desc.length > 0){
          this.listCnt = res.body.desc[0].totalCnt;
        }
        if(res.body.docCnt > 0){
          this.lists = [...this.lists, ...res.body.docs];
          this.currentPage++;
        }
      }
    },
    error =>{
      console.error(error);
    });
  }

  submit(data : any){
    this.modalCtrl.dismiss(data);
  }

  // scroll pagination
  loadData(event) {
    event.target.complete();
    this.getList();
  }

}
