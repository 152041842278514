import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // 비회원 모듈 (메인, 로그인, 회원가입)
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  // 개인회원 모듈
  {
    path: 'personal',
    loadChildren: () => import('./personal/personal.module').then( m => m.PersonalPageModule)
  },
  // 기업회원 모듈
  {
    path: 'enterprise',
    loadChildren: () => import('./enterprise/enterprise.module').then( m => m.EnterprisePageModule)
  },
  // web 모듈
  {
    path: 'web',
    loadChildren: () => import('./web/web.module').then( m => m.WebPageModule)
  },
  // web 메인페이지
  {
    path:'',
    loadChildren: () => import('./web/main/main.module').then( m => m.MainPageModule)
  },
  // web 메인페이지
  {
    path: 'my/:keyId',
    loadChildren: () => import('./web/mhome/mhome.module').then( m => m.MhomePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
