import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ToastService } from 'src/app/service/toast-service';
import { formCheck, ValidatorsExt } from 'src/app/global/global';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { RestApiService } from 'src/app/service/rest-api.service';
import { CONSTANT } from 'src/assets/global-constant';

@Component({
  selector: 'app-find-password',
  templateUrl: './find-password.component.html',
  styleUrls: ['./find-password.component.scss'],
})
export class FindPasswordComponent implements OnInit {

  @Input() loginId: any;
  key: string = 'nokey';
  public pwForm: FormGroup;
  public valid: any;

  constructor(public fb: FormBuilder, public router: Router, public toastCtrl: ToastService, public modalCtrl: ModalController, private restApi: RestApiService) {
    this.pwForm = fb.group({
      CetificationNum: ['', [
        Validators.required
      ]],
      Password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern("^(?=.*[a-zA-Z0-9])(?=.*[a-zA-Z!`~@#$%^&*()_=+{}|?<>,./\-])(?=.*[a-z0-9!`~@#$%^&*()_=+{}|?<>,./\-])(?=.*[A-Z0-9!`~@#$%^&*()_=+{}|?<>,./\-]).{8,}$") // 대문자, 소문자, 숫자, 특수문자 중 2개조합 8자리이상
      ]],
      rePassword: ['', [
        Validators.required,
        Validators.minLength(8),
        ValidatorsExt.match
      ]],
    })
    this.valid = { ...this.pwForm.controls };
  }

  ngOnInit() {
    console.log('pwfind', this.loginId)
  }

  validCheck() {
    // 개인정보 Form Valid Check
    if (formCheck(this.pwForm.controls)) {
      this.toastCtrl.presentToast('입력란을 확인해주세요.')
      return true;
    }
  }

  submit() {
    if (this.validCheck()) {
      return;
    }

    let loginData: any = { Password: this.pwForm.value['Password'], LoginId: this.loginId, key: this.key, Type: 'PC', CetificationNum: this.pwForm.value['CetificationNum']}
    this.restApi.updatePassword(loginData, (res: any) => {
      if (res.header.status === CONSTANT.HttpStatus.OK) {
        if (res.body.docCnt > 0) {
          const info = res.body.docs[0];

          if (info.result == 0) {
            this.toastCtrl.presentToast('잘못된 인증번호이거나 오류가 발생하였습니다.\n다시 시도해 주세요.');
            return;
          } else {
            this.toastCtrl.presentToast('수정 되었습니다.');
            this.modalCtrl.dismiss('ok');
          }
        } else {
          this.toastCtrl.presentToast('잘못된 인증번호이거나 오류가 발생하였습니다.\n다시 시도해 주세요.');
        }
      }
    },
    (error: any) => {
      this.toastCtrl.presentToast('잘못된 인증번호이거나 오류가 발생하였습니다.\n다시 시도해 주세요.');
    });
  }

  cancel() {
    this.modalCtrl.dismiss('cancel');
  }
}
