import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { rootScope } from '../global/global';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  user : BehaviorSubject<any>;
  action : BehaviorSubject<any>;
  sns : BehaviorSubject<any>;
  data: any = {};
  token : String;
  flow : BehaviorSubject<any>;
  constructor() { 
    this.user = new BehaviorSubject<any>('');
    this.action = new BehaviorSubject<any>({});
    this.sns = new BehaviorSubject<any>({});
    this.flow = new BehaviorSubject<any>({});
  }

  sessionIn(param: any) {
    rootScope.setLocalStorage(param);
    this.user.next(param);
  }
  sessionInNomal(param: any){
    rootScope.setSessionStorage(param);
    this.user.next(param);
  }

  sessionOut() {
    rootScope.destroyLocalStorage();
    this.user.next({});
    this.sns.next({});
  }

  getToken() {
    return this.token;
  }

  setToken(token: String) {
    this.token = token;
  }

  // sendData(data: string) {                   //수정
  //   this.dataSource.next(data);             //수정
  // }                                         //수정

  setData(data: any = {}) {
    this.data = data;
  }

  getData(){
    return this.data;
  }
  
  setAction(param : any){
    this.action.next(param);
  }

  setSns(param : any){
    this.sns.next(param);
  }
  
  setFlow(param : any){
    this.flow.next(param);
  }
}
