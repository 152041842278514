import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ToastService } from 'src/app/service/toast-service';

@Component({
  selector: 'app-video-link',
  templateUrl: './video-link.component.html',
  styleUrls: ['./video-link.component.scss'],
})
export class VideoLinkComponent implements OnInit {

  
  @Input() links : Array<any>;
  public url : string;
  public urlError : boolean = false;
  public urlChecker : FormGroup;
  public action : boolean = false;
  constructor(public modalCtrl : ModalController, public fb : FormBuilder, public toastCtrl : ToastService) { 
    this.urlChecker = fb.group({
      url : [null, [
        Validators.pattern(/^https:\/\/youtu.be\/.+$/),
        Validators.required
      ]]
    })
  }

  ngOnInit() {}

  closeModal(){
    this.modalCtrl.dismiss(this.links);
  }

  addLink(){
    this.action = true;                                             // 추가버튼 클릭 후 url 체크 조건에 따라 메세지 출력하기 위한 조건
    const url = this.urlChecker.controls.url;   
    const keyIdx = url.value.lastIndexOf('/');
    const result = 'https://www.youtube.com/embed/' + url.value.substr(keyIdx + 1);

    if(!this.links){
      this.links = [];
    }

    // 등록된 링크중에 중복 검색
    let dupleCheck : boolean;
    this.links.map((item) =>{
      if(item.LinkURL == result){
        dupleCheck = true;
        return;
      }
    });
    
    if(!url.valid){
      return;
    }else if(dupleCheck){
      this.toastCtrl.presentToast('중복된 링크가 존재합니다.');
      return;
    }

    this.links.push({LinkURL : result});
    url.setValue('');
    this.action = false;
  }

  removeLink(idx : number){
    this.links.splice(idx, 1);
  }

}
