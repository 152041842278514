import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {

  @Input('stat') stat : any;
  @Input('srch') srch : any;
  @Output() context = new EventEmitter();

  
  constructor() { 
  }
  
  ngOnInit() {
    this.init();
  }

  getSearch(){
    this.context.emit(this.srch);
  }

  init(){
    this.srch.keyword = this.srch.keyword ? this.srch.keyword : '';
    this.srch.BuildType = this.srch.BuildType ? this.srch.BuildType : '';
    this.srch.TransType = this.srch.TransType ? this.srch.TransType : '';
    this.srch[this.stat.target] = this.srch[this.stat.target] ? this.srch[this.stat.target] : '';
  }
}
