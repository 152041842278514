import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { CONSTANT } from 'src/assets/global-constant';
import { rootScope } from '../global/global';
import { SessionService } from './session.service';
// import { GAlertService } from './g-alert.service';

@Injectable({
  providedIn: 'root'
})
export class StaticVariableService implements OnInit {

  public getRequestActions : any;
  constructor(private session : SessionService) {
    CONSTANT.URL.CONTEXT = this.getContextPath();
    
    var alreadyOccurError = false;
    var serverDead = false;

    // let intercept = {
    //   response : function(JSON) {
    //     //console.info('intercept JSON=====', JSON);
    //     return JSON.data;
    //   },
    //   responseError : (error) => {
    //     console.log('intercept error=====', error);

    //     if (error.data == null) {
    //       this.gAlert.alert('Menu.SERVERERROR4', "Unknown error.");
    //       return null;
    //     }

    //     if ((error.status == -1 || error.status == 400) && !serverDead) {
    //       serverDead = true;
    //       this.gAlert.alert('Menu.SERVERERROR1', {
    //         fn : function() {
    //           this.sessionExpire();
    //           // $state.go('login');
    //         }
    //       });

    //     } else if (error.data && error.data.response === undefined) {
    //       this.gAlert.alert('Menu.SERVERERROR2', error.statusText, {
    //         fn : function() {
    //           console.error('server Error', '[' + error.status + ']' + error.statusText);
    //           if (error.status == 401 || error.status == 400) {
    //             this.sessionExpire();
    //             // $state.go('login');
    //           }
    //         }
    //       });
    //       return null;
    //     } else if (error.data.response.body.docs.errCode == 'ACN0N01-401' || error.data.response.body.docs.errCode == 'ACN1N01-401'
    //         || error.data.response.body.docs.errCode == 'ACN1N02-401' || error.data.response.body.docs.errCode == 'ACN1N03-401'
    //         || error.data.response.body.docs.errCode == 'ACN1N04-401') {
    //       if(window.sessionStorage.isLoggedIn) {
    //         alreadyOccurError = true;
    //         this.sessionExpire();
    //         // $state.go('login');
    //         return null;
    //       }
    //     } else if (error.data.response.body.docs.errCode === 'ACN1N00-403') {
    //       console.log(error.data);
    //       this.gAlert.alert('Menu.SERVERERROR3', error.data.response.body.docs.errMessage, {
    //         fn : function() {
    //           console.error(error.data.response.body.docs.errMessage);
    //           // $state.go('login');
    //         }
    //       });
    //       return null;
    //     } else {
    //       return error.data;
    //     }

    //     this.gAlert.alert('Menu.SERVERERROR4', error.data.response.body.docs.errMessage);
    //     console.error(error.data.response.body.docs.errCode, error.data.response.body.docs.errMessage);
    //   }
    // };

    this.getRequestActions = {
      defaultAction : function() {
        return {
          headers : new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
        };
      },
      getStandardAction : function() {
        return {
          getOne : {
            method : 'GET',
            headers : {
              'Content-Type' : 'application/json'
            },
            // interceptor : intercept,
          },
          getList : {
            method : "GET",
            headers : {
              'Content-Type' : 'application/json'
            },
            // interceptor : intercept,
          },
          create : {
            method : "POST",
            headers : {
              'Content-Type' : 'application/json'
            },
            // interceptor : intercept,
          },
          modify : {
            method : "PUT",
            headers : {
              'Content-Type' : 'application/json'
            },
            // interceptor : intercept,
          },
          remove : {
            method : "DELETE",
            headers : {
              'Content-Type' : 'application/json'
            },
            // interceptor : intercept,
          },
        };
      },
    }
  }

  ngOnInit(){

  }

  getContextPath() {
    var offset=location.href.indexOf(location.host)+location.host.length;
    if (offset + 1 >= location.href.length)
      return "";

    var offset2 = location.href.indexOf('/',offset+1);
    if (offset2 == -1) offset2 = location.href.length;

    var ctxPath=location.href.substring(offset+1, offset2);

    if (ctxPath == '#' || ctxPath == 'i.html#' || ctxPath == 'm.html#' || ctxPath == 'min.html#' || ctxPath == 'i.html' || ctxPath == 'm.html' || ctxPath == 'min.html')
      ctxPath = '';

    return ctxPath;
  }


  sessionExpire() {
    window.sessionStorage.removeItem('isLoggedIn');
    window.sessionStorage.removeItem('loginUserId');
    window.sessionStorage.removeItem('loginUserIdx');
    window.sessionStorage.removeItem('loginSysAdminYN');
    window.sessionStorage.removeItem('loginUserName');
    window.sessionStorage.removeItem('loginUserGbn');
    window.sessionStorage.removeItem('loginUserDept');
    window.sessionStorage.removeItem('loginUserDeptCode');
    window.sessionStorage.removeItem('loginComGbn');
    window.sessionStorage.removeItem('loginUserType');
    window.sessionStorage.removeItem('loginUserEmail');
    window.sessionStorage.removeItem('loginTenantId');
    window.sessionStorage.removeItem('loginTenantName');
    window.sessionStorage.removeItem('loginTenantDir');
    window.sessionStorage.removeItem('loginEmail');
    window.sessionStorage.removeItem('loginTelNo');
    window.sessionStorage.removeItem('srchTenantId');
    window.sessionStorage.removeItem('srchTenantName');
  }

  getUrl(uri : string) {
    var domainPort = CONSTANT.URL.PORT == null || CONSTANT.URL.PORT == "" ? "" : ":" + CONSTANT.URL.PORT;
    var domainContext = CONSTANT.URL.CONTEXT == null || CONSTANT.URL.CONTEXT == "" ? "" : '/' + CONSTANT.URL.CONTEXT;
    var domainVersion = CONSTANT.URL.VERSION == null || CONSTANT.URL.VERSION == "" ? "" : '/' + CONSTANT.URL.VERSION;

    var url = CONSTANT.URL.HTTP_PROTOCOL + '//' +
    CONSTANT.URL.API_SERVER_DOMAIN + domainPort +
    domainVersion + uri;

    if (this.session.user.value.userkey && this.session.user.value.TenantId) {
      if(url.indexOf('?') > -1){
        url = url + '&userkey=' + this.session.user.value.userkey + "-" + this.session.user.value.TenantId;
      }else{
        url = url + '?userkey=' + this.session.user.value.userkey + "-" + this.session.user.value.TenantId;
      }      
    }

    //var url = domainContext + domainVersion + uri;
    return url;
  }

  getFileDownloadUrl(FileKey) {
   
    if(this.getContextPath() == 'my' || this.getContextPath() == 'web' || this.getContextPath() == 'home') {
      return this.getUrl('/openapi/attach/' + FileKey + '/image.do');
    } else {
      return this.getUrl('/attach/file/' + FileKey + '/download.do');
    }
  }

  getDateFormat(langCode, useDateTime) {
    let format : string;
    switch (langCode) {
      case 'ko':
        format = 'yyyy.MM.dd';
        break;
      case 'zh':
        // break;
      case 'ja':
        format = 'yyyy.MM.dd';
        break;
      case 'en':
        format = 'MM/dd/yy';
        break;
      default:
        format = 'MM/dd/yy';
        break;
    }
    return useDateTime ? format + ' HH:mm:ss' : format;
  }
}
