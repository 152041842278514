import { Component, OnInit , Input} from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { RestApiService } from 'src/app/service/rest-api.service';
import { StaticVariableService } from 'src/app/service/static-variable.service';
import { ToastService } from 'src/app/service/toast-service';
import { CONSTANT } from 'src/assets/global-constant';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { rootScope } from 'src/app/global/global';
import { Clipboard } from "@angular/cdk/clipboard";

@Component({
  selector: 'app-mini-home',
  templateUrl: './mini-home.component.html',
  styleUrls: ['./mini-home.component.scss'],
})
export class MiniHomeComponent implements OnInit {

  @Input('keyId') keyId : string;
  public lists : any = [];
  public reviewsLists : any = [];
  public tenantNm : string = "";
  public custNm : string = "";
  public custImg : string = "";
  public comments : string = "";
  public listCnt : number = 0;
  public reviewCnt : number = 0;    // 총 후기 수 
  public productCnt : number = 0;   // 총 매물 수 
  public scoreSum : number | string = 0;     // 평점
  public currentPageP : number = 1; // 매물목록 페이지 Number
  public currentPageR : number = 1; // 고객후기 페이지 Number
  public prodMainImg : string = ""; // 매물 대표사진
  public specYN1 : string = "";     // 전문분야 여부 : 지식산업센터 
  public specYN2 : string = "";     // 전문분야 여부 : 상가/점포
  public specYN3 : string = "";     // 전문분야 여부 : 아파트 
  public specYN4 : string = "";     // 전문분야 여부 : 오피스텔 
  public specYN5 : string = "";     // 전문분야 여부 : 원룸/투룸+ 
  public specYN6 : string = "";     // 전문분야 여부 : 빌라/단독주택 
  public spec : string = "";        // 중개사 Total 전문분야 
  public rootScope : any = rootScope;
  
  items: GalleryItem[];
  constructor(private restApi : RestApiService, public modalCtrl : ModalController, private StaticVariable : StaticVariableService,
              public alertCtrl: AlertController, public toastCtrl : ToastService, public gallery: Gallery, public lightbox: Lightbox,public toast: ToastService, private clipboard : Clipboard) { }
    
  public imageData : any = [];
  
  ngOnInit() {
    this.currentPageP = 1;
    this.currentPageR = 1;
    this.getEnterPriseData();
    // this.getList();
    this.getReviewList();
  }

  /*****************
   * 중개사 정보 조회
   *****************/
  getEnterPriseData() {
    let data : any = {
      IdName : 'CustId',
      tableNm : 'anycustomer',
      keyId : this.keyId,
    };
    this.restApi.selectEnterPriseData(data, res=>{
      if(res.header.status === CONSTANT.HttpStatus.OK){
        if(res.body.desc.length > 0){
          let result = res.body.desc[0];
          this.reviewCnt = result.reviewCnt;    // 총 후기 수 
          this.productCnt = result.productCnt;  // 총 매물 수 

          // 전문분야 여부
          this.specYN1 = res.body.desc[0].SpecYN1;        // 지식산업센터 
          this.specYN2 = res.body.desc[0].SpecYN2;        // 상가/점포
          this.specYN3 = res.body.desc[0].SpecYN3;        // 아파트
          this.specYN4 = res.body.desc[0].SpecYN4;        // 오피스텔
          this.specYN5 = res.body.desc[0].SpecYN5;        // 원룸/투룸+
          this.specYN6 = res.body.desc[0].SpecYN6;        // 빌라/단독주택

          if (this.reviewCnt == 0) this.scoreSum =0; 
          // else this.scoreSum = (res.body.desc[0].CorrectScoreSum + 
          //                       res.body.desc[0].ProfScoreSum + 
          //                       res.body.desc[0].KindScoreSum) / 3 / this.reviewCnt * 10 / 10 ;       // 중개사 전체 평점
          else{
            this.scoreSum = this.roundUp(result.CorrectScoreSum, result.ProfScoreSum, result.KindScoreSum);
          }
        }

        if(res.body.docs.length > 0){
          this.custNm = res.body.docs[0].CustNm;          // 고객명
          this.tenantNm = res.body.docs[0].TenantNm;      // 중개사 명 
          this.comments = res.body.docs[0].Comments;      // 중개사 소개
          let docslength = res.body.docs.length;
          this.custImg = this.StaticVariable.getFileDownloadUrl(res.body.docs[docslength-1].TenantId+'/'+res.body.docs[docslength-1].PhysicalFolderNm+'/'+res.body.docs[docslength-1].PhysicalFileNm); // 고객 이미지
        }

        
        
        
        if (this.specYN1 == 'Y') this.spec += '지식산업센터, ';
        if (this.specYN2 == 'Y') this.spec += '상가/점포, '; 
        if (this.specYN3 == 'Y') this.spec += '아파트, ';
        if (this.specYN4 == 'Y') this.spec += '오피스텔, ';
        if (this.specYN5 == 'Y') this.spec += '원룸/투룸, ';
        if (this.specYN6 == 'Y') this.spec += '빌라/단독주택, ';
        
        // 불필요 ','(Comma) 제거 
        if(this.spec[0] == ',') this.spec = this.spec.substring(1);
        if(this.spec[this.spec.length-2] == ',') this.spec = this.spec.substring(0, this.spec.length-2);
      }
    },
    error => {
      console.error(error);
    });

  }

  /*********************
   * 매물 목록 조회
   *********************/
//   getList() {
    
//     let data : any = {
//       IdName : 'ProductId',
//       tableNm : 'anyproduct',
//       uploadFileList : [],
//       keyId : this.keyId,
//       page : this.currentPageP,
//       viewSize : 4,
//     };
//     this.restApi.selectPropListMin(data, res=>{
      
//       if(res.header.status === CONSTANT.HttpStatus.OK){
//         if(res.body.desc.length > 0){
//           this.listCnt = res.body.desc[0].totalCnt;
//         }
//         if (res.body.docCnt > 0) {
//           // 더보기 버튼이 필요 없을 시 버튼 숨김 처리 
//           if(res.body.docCnt != 4 ) document.getElementById("prodMoreBtn").style.display = "none";
//           this.lists = [...this.lists, ...res.body.docs];
//           var i = 0 ;
//           var j = (this.currentPageP-1)*4;
//           var k = 0;
          
//           this.lists.forEach(list => {
            
//             if(list.uploadFileList){

//               list.uploadFileList.forEach(item => {
                
//                 var imageUrl = "";
//                 imageUrl = this.StaticVariable.getFileDownloadUrl(item.tenantId+'/'+item.PhysicalPath+'/'+item.PhysicalFileNm);
//                 this.imageData.push({
//                   srcUrl : imageUrl, 
//                   previewUrl : imageUrl
//                 });
//               });

//               // Lightbox
//               // Creat gallery items
//               this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
//               // Get a lightbox gallery ref
              
              
//               const lightboxRef = this.gallery.ref('lightbox'+i);
//               // Add custom gallery config to the lightbox (optional)
//               lightboxRef.setConfig({
//                 imageSize: ImageSize.Contain,
//                 thumbPosition: ThumbnailsPosition.Bottom
//               });
//               // Load items into the lightbox gallery ref
//               lightboxRef.load(this.items);
//               console.warn(this.imageData[0]);
//               if(list.uploadFileList.length == 0) {
//                 list.prodMainImg = '';
//               } else {
//                 list.prodMainImg = this.imageData[0].previewUrl;
//               }
//               this.imageData = [];
              
//               /*
//               k =  i+j;
//               console.warn(i,j,k);
//               list.files = [];
//               var imageUrl = "";
//               list.uploadFileList.forEach(item => {
//                 list.files.push(this.StaticVariable.getFileDownloadUrl(item.tenantId+'/'+item.PhysicalPath+'/'+item.PhysicalFileNm));
//                 imageUrl = this.StaticVariable.getFileDownloadUrl(item.tenantId+'/'+item.PhysicalPath+'/'+item.PhysicalFileNm);
//                 this.imageData.push({srcUrl : this.StaticVariable.getFileDownloadUrl(item.tenantId+'/'+item.PhysicalPath+'/'+item.PhysicalFileNm), previewUrl : this.StaticVariable.getFileDownloadUrl(item.tenantId+'/'+item.PhysicalPath+'/'+item.PhysicalFileNm)});
//                 if(imageUrl == undefined) {
//                   res.body.docs[i].prodMainImg = '';
//                 } else {
//                   res.body.docs[i].prodMainImg = imageUrl;
//                 }
//               });
              
              
//               res.body.docs[i].imageData = this.imageData;
              
//               // Lightbox
//               // Creat gallery items
//               this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));
//               // Get a lightbox gallery ref
              
              
//               const lightboxRef = this.gallery.ref('lightbox'+k);
//               // Add custom gallery config to the lightbox (optional)
//               lightboxRef.setConfig({
//                 imageSize: ImageSize.Contain,
//                 thumbPosition: ThumbnailsPosition.Bottom
//               });
//               // Load items into the lightbox gallery ref
//               lightboxRef.load(this.items);
// */
              
             
//               i++; 
//             }
            
//           });

//           this.currentPageP++;

//         } else {
//           document.getElementById("prodMoreBtn").style.display = "none";
//         }

//       }
      
//     },
//       error => {
//         console.error(error);
//       });
//   }

  /*********************
   * 고객 후기 목록 조회
   *********************/
  getReviewList(){
    let data = {
      keyId : this.keyId,
      page : this.currentPageR,
      viewSigize : 4
    };

    this.restApi.reviewsForMin(data, res =>{
      if(res.header.status === CONSTANT.HttpStatus.OK){
        // 더보기 버튼이 필요 없을 시 버튼 숨김 처리 
        if(res.body.docCnt != 4 ) document.getElementById("reviewMoreBtn").style.display = "none";

        if (res.body.docCnt > 0) {
          this.reviewsLists = res.body.docs;
          this.currentPageR++;

        } else {
          document.getElementById('reviewMoreBtn').style.display = 'none';
        }

      }

    },
    error => {
      console.error(error);
    });

  }

  /*********************************
   * 페이징 처리 
   * flag == 'P' ->  매물목록
   * flag == 'R' ->  고객후기 목록
   *********************************/
  loadData(flag) {
    if(flag = 'P') {
      // this.getList();
    } else {
      this.getReviewList();
    } 
  }

  roundUp(num1, num2, num3){
    return Math.round(((num1 + num2 + num3) / 3) / this.reviewCnt * 10) / 10;
  }

  openGallery(index : number ) {
    this.lightbox.open(0, 'lightbox'+index);
  }

  copyUrl(){
    this.toast.presentToast('클립보드에 복사되었습니다.');
    this.clipboard.copy('https://anybang.co.kr/my/' + this.keyId);
  }
}