import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {

  @Input('total') total: number;
  @Input('viewSize') viewSize: number;
  @Output() dataChange = new EventEmitter();
  @Input() 
  get current (){
    return this.result;
  }

  set current(value){
    this.result = value;
    this.dataChange.emit(this.result);
  }

  public result : any;
  totalPage: number;
  pageList = [];

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes) {
    this.calcTotalPage();
  }

  calcTotalPage(){
    const divd = Math.floor((this.total - 1) / this.viewSize);
    console.log((this.total - 1) % this.viewSize);
    this.totalPage = (this.total - 1) % this.viewSize === 0 ? divd : divd + 1;
    this.makePageList();
  }

  makePageList(){
    this.pageList = [];
    if(this.current >= 3) {
      if(this.totalPage - this.current > 2) {
        for(let i = this.current - 2; i < this.current + 3; i++) {
          this.pageList.push(i);
        }
      }else{
        for(let i = this.current - (4 - (this.totalPage - this.current)); i < this.totalPage + 1; i++) {
          if(i > 0) {
            this.pageList.push(i);
          }
        }
      }
    }else {
      if(this.totalPage > 5) {
        for(let i = 1; i < 6; i++) {
          this.pageList.push(i);
        }
      }else {
        for(let i = 1; i <= this.totalPage; i++) {
          this.pageList.push(i);
        }
      }
    }
  }

  changePage(page){
    if(page < 1 || page > this.totalPage) {
      return;
    }
    this.current = page;
    this.makePageList();
  }
}
