import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { $ } from 'protractor';
import { RestApiService } from 'src/app/service/rest-api.service';
import { StaticVariableService } from 'src/app/service/static-variable.service';
import { ToastService } from 'src/app/service/toast-service';
import { CONSTANT } from 'src/assets/global-constant';

@Component({
  selector: 'app-prop-detail',
  templateUrl: './prop-detail.component.html',
  styleUrls: ['./prop-detail.component.scss'],
})
export class PropDetailComponent implements OnInit {

  public data : any = {};
  public toggleVideo : boolean = true;
  public togglePhoto : boolean = true;
  @Input() keyId : string;
  @Input() auth : boolean;
  @Input() flag : boolean;
  constructor(public modalCtrl : ModalController, private restApi : RestApiService, private router : Router, private StaticVariable : StaticVariableService,
              public alertCtrl: AlertController, public toastCtrl : ToastService) { }

  ngOnInit() {
    this.getList();
  }

  getList(){
    let data = {
      ProductId : this.keyId,
      IdName : 'ProductId',
      tableNm : 'anyproduct',
      uploadFileList : [],
      sort : 'RegDate'
    };

    this.restApi.selectPropList(data, res=>{
      if(res.header.status === CONSTANT.HttpStatus.OK){
        if(res.body.docCnt > 0){
          this.data = res.body.docs[0];
          console.log(this.data);
          if(this.data.uploadFileList){
            this.data.files = [];
            this.data.uploadFileList.forEach(item => {
              this.data.files.push(this.StaticVariable.getFileDownloadUrl(item.PhysicalFileNm));
            });
          }
        }
      }
    },
    error =>{
      console.error(error);
    });
  }

  goEdit(){
    this.modalCtrl.dismiss();

    this.router.navigateByUrl('/enterprise/prop-edit/' + this.keyId + '/' + this.data.BuildType);
  }

  deleteProp(id : string){
    let data = {
      ProductId : id
    }

    this.restApi.deleteProperty(data, res =>{
      if(res.header.status === CONSTANT.HttpStatus.OK){
        this.toastCtrl.presentToast('삭제되었습니다.');
        this.getList();
      }else{
        this.toastCtrl.presentToast('삭제에 실패했습니다.');
      }
    },
    error => {
      this.toastCtrl.presentToast('에러가 발생했습니다.');
      console.error(error);
    });
  }

  async deleteConfirm(keyId: string) {
    const alert = await this.alertCtrl.create({
      message: '삭제하시겠습니까?',
      buttons: [
        {
          text: '취소',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {

          }
        }, {
          text: '삭제',
          handler: () => {
            this.deleteProp(keyId);
          }
        }
      ]
    });

    await alert.present();
  }
  
}
